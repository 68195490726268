* , *:before, *:after
 {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/www/fonts/ProximaNovaRegular.eot');
  src: url('/www/fonts/ProximaNovaRegular.eot?#iefix') format('embedded-opentype'),
  url('/www/fonts/ProximaNovaRegular.woff') format('woff'),
  url('/www/fonts/ProximaNovaRegular.ttf') format('truetype');
}
html{
  font-size: 14px;
}
body {
  font-family: 'Proxima Nova', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  margin: 0 auto;
  font-size: 16px;
}
html[lang=he] body {
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}
h1{
  font-size: 2.5rem;
  &.title {
    width: max-content;
    margin: 0 auto;
    display: flex;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    align-items: center;
    &::before {
      content: "";
      width: 25px;
      background: linear-gradient(76.69deg, #D90303 -27.71%, #D93838 98.92%);
      height: 40px;
      display: inline-block;
      clip-path: polygon(17px 0%, 100% 0%, calc(100% - 17px) 100%, 0% 100%);
    }
  }
}
a{
  text-decoration: none;

}
a:visited{
  color: inherit;
}

.clearfix {
  display: flow-root;
}

 .mpd2_5 {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}
 .d-block{
   display: block;
 }

 .text-uppercase{
   text-transform: uppercase;
 }

.square100{
  padding-top: 100%;
  width: 100%;
  position: relative;
  /*  border: 1px solid red;*/
  &:before {
    display: block;
    content: "";
    width: 100px;
  }
  &>div{

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.square80{
  padding-top: 80%;
  width: 100%;
  position: relative;
  /*  border: 1px solid red;*/
  &:before {
    display: block;
    content: "";
    width: 100px;
  }
  &>div{

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.square60{
  padding-top: 60%;
  width: 100%;
  position: relative;
  /*  border: 1px solid red;*/
  &:before {
    display: block;
    content: "";
    width: 100px;
  }
  &>div{

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.invisible {
  visibility: hidden;
}
.image-gallery-slide .image-gallery-description{
  background: rgba(0,0,0,.75) !important;
  text-align: start !important;
}

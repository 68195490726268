@import '../../styles/themes/default';
@import '../../styles/mixin';
@import '../../styles/patterns';

.package_checkout {
  @include flex-column;
  padding: 1rem;
  overflow: hidden;
  position: relative;

  &_layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 100;
    @include align-justify();
    transition: 3.5s ease-out;

    &.disable_layer {
      left: -900px;
      right: unset;

    }
  }

  &_success {
    @include column-align-justify();
    background-color: rgba(255, 255, 255, 0.95);
    width: 100%;

    &_link {
      & a {
        @include margin-start(15px);
        text-decoration: underline;
      }
    }
  }

  & .checkout_bikes {
    margin-bottom: 20px;
  }

  & .order_add_date {
    @include justify(space-between);

    .mobile & {
      flex-direction: column;
    }
  }

  & .order_add_date_side{
    .mobile & {
      margin-top: 20px;
    }

    &_info{
      flex-grow: 1;
      @include align-justify();
      font-weight: 700;
    }
  }

  & .time-s-row {
    @include justify(flex-end);
    font-size: .95em;

    .mobile & {
      font-size: 1.2em;
    }
  }

  & .time-s-row .time-label {
    width: 35%;
  }

  & .time-s-row .time-day {
    width: 35%;
  }

  & .time-s-desc{
    @include padding-start(15px);
  }

  .checkout .note-data {
    .mobile & {
      padding: 0;
    }
  }

  &_info {
    @include flex-column;
    width: 100%;
    background-color: #dedede;
    padding: 0.75em;

    &_line{
      @include align-items();
      font-size: 20px;
      padding: 3px 0;
      font-weight: 600;

      .mobile & {
        font-size: 27px;
      }

      &.second_line{
        color: #d90303;
        font-size: 18px;
        font-style: italic;


        .mobile & {
          font-size: 22px;
        }
      }
    }

    &_item {
      @include align-items();

      & +.package_checkout_info_item{
        @include margin-start(15px);
      }
    }
  }
}
.visitors{
  display: block;
  padding: 0 25%;
  visibility: hidden;
  .view-count {
    margin: 0 10px 10px;
    width: 100%;
    ;
  }
  .view-count-title,.last-order-title{
      font-style: italic;
    font-size: 80%;

  }
  .view-count-value,.last-order-value{
     color: red;
  }
}


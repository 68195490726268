@import "../styles/mixin";
@import "../styles/themes/default";

.certificate {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: url("/www/images/bikes/back-moto.jpg") no-repeat bottom;
  padding-bottom: 30px;

  &_wrap {
    @include flex-column();
    /*    max-width: 1200px;
       min-width: 1200px;
    max-width: 95%;
    min-width: 95%;
    margin: 0 auto;*/

    &_header {
      @include column-align-items();
      background: $secondary-color;
      border: 1px solid #555;
      @include padding-start(10px);
      @include padding-end(10px);
      width: 100%;
      box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
      margin-bottom: 1rem;

      h2 {
        @include column-align-items();
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;

        .mobile & {

          font-size: 2.2rem;
        }
      }
    }
  }

  &_area{
    @include flex-column;
    width: 100%;
    padding: 30px 0;

    &_header{
      font-weight: bold;
      text-align: center;
      width: 100%;
      font-size: 22px;
      margin-bottom: 20px;
    }
  }

  &_create{
    @include flex-column;
    width: 100%;

    &_voucher{
      @include flex-column;
      min-width: 400px;
      width: 400px;
      max-width: 400px;
      @include margin-start(15px);

      .mobile & {
        min-width: 95vw;
        width: 95vw;
        max-width: 95vw;
        margin: 0 auto;
        @include margin-start(0);

        margin-bottom: 20px;
      }

      &_wrap{
        width: 100%;
        height: 275px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        position: relative;
        -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.15);
        -moz-box-shadow: 0 10px 20px rgba(0,0,0,.15);
        box-shadow: 0 10px 20px rgba(0,0,0,.15);
        border : 1px solid #e2e2e2;
        @include d-flex;
      }

      &_top{
        @include align-justify();
        height: 195px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        flex-grow: 1;
        transition: 0.5s easy;
      }

      &_bottom{
        background-color: rgba(0, 0, 0, 0.35);
        height: 0%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: #fff;
        @include d-flex;
        transition: 0.5s easy;

        &.with_text{
          height: 80px;
        }
      }

      &_text{
        @include align-items();
        @include padding-start(20px);
        width: calc(100% - 100px);
        border-bottom-left-radius: 10px;
        font-size: 0.85em;
        white-space: pre-wrap;
      }

      &_right{
        @include column-justify(space-between);
        width: 0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: $secondary-color;
        background-color: #f60;
        transition: 0.5s easy;
        display: none;


        &.with_price{
          width: 100px;
          min-width: 100px;
        }
      }


      &_price{
        border-top-right-radius: 10px;
        @include column-align-justify();
        flex-grow: 1;

        &_text{
          @include align-justify();
          margin-bottom: 20px;
          height: 40px;
        }

        &_value{
          @include align-justify();
          font-size: 1.5em;
        }
      }
      &_code{
        @include align-justify();
        border-bottom-right-radius: 10px;
        background-color: #c00202;
        background-color: $secondary-color;
        background-color: #000;
        color: #fff;
        height: 80px;
        font-size: 1.25em;
      }

      &_left{
        @include flex-column;
        flex-grow: 1;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &_logo{
        position: absolute;
        top: 5px;
        left: 5px;
        display: flex;
        width: 66px;
        height: 58px;
        padding: 0;
        /*background: url("/www/images/moto-logo.svg") no-repeat;*/
        background: url("/www/images/logo.png") no-repeat;
        background-size: cover;
        cursor: pointer;
      }
    }
  }

  &_order{
    @include flex-column;
    width: 100%;
  }

  &_checkout{
    margin: 1rem auto;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 500px;
    width: 100%;

    &_layer{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255,255,255,1);
      z-index: 100;
      @include align-justify();
      transition: 3.5s ease-out;

      &.disable_layer{
        left: -1900px;
        right: unset;

      }
    }

    &_success{
      @include column-align-justify();
      background-color: rgba(255,255,255,0.95);
      width: 100%;
    }

    &_page{
      @include justify(space-between);
      width: 100%;

      .mobile & {
        flex-direction: column;
      }
    }

    & .main-data {
      position: relative;

      & .disabled-data{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        backdrop-filter: blur(4px);
      }
    }

    & .disabled-block {
      & .checkbox-wrapper-12 .cbx{
        filter: blur(4px);
      }
    }

    & .checkout .accessor .heading {
      position: static;
    }

    & .checkbox-wrapper-12{
      position: static;
    }
  }

  &_images{
    @include flex-wrap();
    padding: 20px;

    &_item{
      width: calc(100% / 4 - 10px);
      height: max-content;
      aspect-ratio: 3 / 2 ;
      border-radius: 3px;
      @include align-justify();
      @include margin-end(10px);
      cursor: pointer;
      padding: 2px;
      position: relative;

      & img {
        width: 100%;
        border-radius: 3px;
      }

      &.new_image{
        border-radius: 3px;
        background-color: rgb(245, 245, 245);
        border: 1px solid #aaa;

        & svg {
          font-size: 23px;
        }
      }
      &.checked_image{
        border: 2px solid $secondary-color;
      }

      &_check{
        background-color: #fff;
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 5px;
        height: 16px;
        width: 16px;

        & svg {
          color: $secondary-color;
          border-radius: 5px;
        }
      }
    }
  }

  &_text{
    @include flex-column;
    width: 100%;

    & .input-item{
      height: max-content;
    }

    & textarea {
      width: 100%;
    }

    &_note{
      @include align-justify(center,flex-end);
      font-size: 0.9em;
    }
  }

  &_rules{
    @include flex-column;
    width: 100%;
    padding: 5px 20px;

    &_header{
      text-align: center;
      font-weight: bold;
      margin-bottom: 12px;
    }

    &_items{
      font-size: 0.875em;
      @include flex-column;
      width: 100%;
    }

    &_item{
      @include flex-column;
      width: 100%;
      margin-bottom: 12px;
    }

    &_name{
      font-weight: bold;
      margin-bottom: 6px;
    }
    &_text{
      font-style: oblique;
      text-align: justify;
    }
  }
}


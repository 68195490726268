@import '../../styles/mixin';
@import '../../styles/themes/default';

.language{
  @include align-justify(center,flex-end);
  order: 3;
/*  width: 100px;*/
  width: max-content;

  &_string{
    @include align-items();

    .mobile & {
      gap: 10px;
    }

    &_item{
      @include align-justify();
      @include margin-end(4px);

      &.active_language{
        & a {
          border: 3px solid gold;
          box-shadow: 2px 2px 6px rgb(0 0 0 / 60%);

          &:hover{
            border: 3px solid gold;
          }
        }
      }

      & a {
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;
        width: 35px;
        height: 35px;
        border: 3px solid rgba(0,0,0,0.075);

        .mobile & {
          width: 48px;
          height: 48px;
        }

        &:hover{
          border: 3px solid rgba(0,0,0,0.15);
          box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);

          & img {
            transform: translateZ(0) scale(1.05);
            filter: contrast(1.3);
          }
        }

        & img{
          width:100%;
          height:100%;
          object-fit: cover;
          filter: contrast(1);
          transition: filter 0.6s steps(12, end), transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transform: translateZ(0) scale(0.975);
          transform-origin: 50% 50%;
        }
      }
    }
  }

  &__widget{
    width: 3.8823529412rem;
    height: 3.8823529412rem;

  }

  &__list{
    @include flex-column;
    background: 0 0;
    overflow: hidden;
    box-sizing: content-box;
    border: solid 1px transparent;
    width: 66px;
    height: 65px;
    -webkit-transition: height .1s 0s,border .25s 0s,background .25s 0s;
    transition: height .1s 0s,border .25s 0s,background .25s 0s;

    &:hover{
      border-color: rgba(0,0,0,.15);
      background: #fff;
    }

    &-item{
      width: 66px;
      height: 66px;
      @include flex-column();

      &.active_language{
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
      }

      & svg{
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
        width: 100%;
      }
    }

    &-link{
      background: none center no-repeat;
      background-size: 2.7058823529rem 1.8039215686rem;
      height: 35px;
      margin: 10px;

      .active_language & {
        @include column-align-justify(flex-end);
        pointer-events: none;
        background-position: top 0.5882352941rem left 50%;

      }
    }
  }
}
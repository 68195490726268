#error_panel{
  z-index: 1021;
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;

  .error-item{
    position: relative;
    svg{
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }
  }
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
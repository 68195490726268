@import '../styles/themes/default';
@import '../styles/mixin';

.photos {
  @include flex-column;
  width: 100%;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  background-image: url("/www/images/back-photos.jpg");
  background-size: cover;
  background-position: center;
  padding-bottom: 30px;

  &_wrap{
    @include flex-column();
    max-width: 95%;
    min-width: 95%;
    margin: 0 auto;
  }

  &_header{
    @include column-align-items();
    background: $secondary-color;
    border: 1px solid #555;
    @include padding-start(10px);
    @include padding-end(10px);
    width: 100%;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
    margin-bottom: 1rem;

    h2 {
      @include column-align-items();
      margin-left: auto;
      margin-right: auto;
      font-weight: 500;
    }
  }

  &_area{
    background: rgba(250, 250, 250, 0.7);
    box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
    min-height: 200px;
    max-width: 95%;
    margin: 0 auto;
    padding: 1rem;
  }

  & .react-photo-gallery--gallery{
    z-index: 2;
    @include flex-column;
    width: 100%;

    & img {
      cursor: pointer;
      @include filter-contrast();
      @include transition(filter .6s steps(12, end), transform .4s cubic-bezier(.785, .135, .15, .860));
      @include transform(translateZ(0) scale(.9775));
      @include transform-origin(50% 50%);
      .mobile & {
        filter: none;
        transition: none;
        transform: none;
        width: 100%;
        height: 100%;
      }

      &:hover{
          @include transform(translateZ(0) scale(1));
          @include filter-contrast(1.5);
      }
    }
  }
}
@import '../../../styles/themes/default';
@import '../../../styles/mixin';

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

.login_card {
  width: 100%;
  height: 450px;
  padding: 3px;
  position: relative;
  overflow: hidden;
  @include column-align-justify();
  text-align: center;
  color: rgb(88 199 250 / 0%);
  font-family: Arial, Helvetica, sans-serif;

  .mobile & {
    height: 600px;
  }
}

.login_card:hover {
  color: rgb(88 199 250 / 100%);
  transition: color 1s;
}

.login_card::before {
  content: "";
  width: 102%;
  height: 102%;
  border-radius: 8px;
  background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
  /*  background-image: linear-gradient(var(--rotate), #fdf7f7, #f1e8e8 43%, #efd8d8);*/
  position: absolute;
  z-index: -1;
  top: -1%;
  left: -1%;
  animation: spin 12s linear infinite;
}

.login_card::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
  /*  background-image: linear-gradient(var(--rotate), #fdf7f7, #f1e8e8 43%, #efd8d8);*/
  opacity: 1;
  transition: opacity .5s;
  animation: spin 12s linear infinite;
}


.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: absolute;
  transition: top .5s;

  &.login_type {
    top: 0;

    .forgot & {
      top:-450px;

      .mobile & {
        top: -600px;
      }
    }
  }

  &.forgot_type {
    top: 450px;

    .forgot & {
      top:0 !important;
    }

    .mobile & {
      top: 600px;
    }
  }

  &.none {
    display: none;
  }

  .form-box {
    position: relative;
    width: 100%;
    height: 450px;
    background: transparent;
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    /*    color: #000;*/

    .mobile & {
      height: 600px;
    }
  }

  .form-value{
    .mobile & {
      width: 75%;
      margin: 0 auto;
    }
  }

  .form-message {
    position: absolute;
    font-size: 1.25em;
    font-weight: 600;
    top: 30px;
    color: red;

    &.good-message{
      color: lime;
    }

    .mobile & {
      font-size: 1.75em;
    }
  }


  h1 {
    width: max-content;
    margin: 0 auto;
    display: flex;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    align-items: center;

    &:before {
      content: "";
      width: 25px;
      background: linear-gradient(76.69deg, #D90303 -27.71%, #D93838 98.92%);
      height: 40px;
      display: inline-block;
      clip-path: polygon(17px 0%, 100% 0%, calc(100% - 17px) 100%, 0% 100%);
    }
  }

  .inputbox {
    position: relative;
    margin: 30px auto;
    width: 310px;
    border-bottom: 2px solid #fff;
    /*    border-bottom: 2px solid #000;*/

    &.error {
      border-color: red;
      border-width: 2px;
    }

    .mobile & {
      width: 100%;
      margin: 60px auto;
    }
  }

  .inputbox label {
    position: absolute;
    bottom: 0;
    @include start(5px);
    transform: translateY(-50%);
    font-size: 1em;
    pointer-events: none;
    transition: .5s;

    .mobile & {
      font-size: 1.775rem;
    }
  }

  input:focus ~ label, input.not_empty ~ label {
    bottom: 25px;

    .mobile & {
      bottom: 32px;
    }
  }

  .inputbox input {
    width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 0.85em;
    padding: 0 35px 0 5px;
    color: #d0d0d0;
    /*    color: #383737;*/

    .mobile & {
      font-size: 1.75rem;
      color: #fff;
      height: 60px;
    }

    html[lang=he] & {
      unicode-bidi: plaintext;
      padding: 0 5px 0 50px;
    }
  }

  .inputbox svg {
    position: absolute;
    @include end(8px);
    font-size: 1.25em;
    top: 10px;
    cursor: pointer;

    .mobile & {
      font-size: 2.5rem;
    }
  }

  .form_bottom {
    @include column-align-items(flex-start);
    width: 310px;
    margin: 10px auto;

    .mobile & {
      width: 100%;
    }
  }

  .link {
    font-size: 0.725em;
    margin-bottom: 1rem;
    cursor: pointer;
    text-decoration: underline;

    html[lang=he] & {
      unicode-bidi: plaintext;
    }

    .mobile & {
      font-size: 1.2em;
    }
  }

  .info {
    text-decoration: none;
    font-size: 0.7em;
    font-style: italic;

    .mobile & {
      font-size: 0.95em;
    }
  }

  .btn_more {
    width: 310px;
    margin: 0 auto;

    .mobile & {
      width: 100%;
      padding: 1.375rem 2.5rem;
      font-size: 1.5rem;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: #d0d0d0;
    /*  -webkit-text-fill-color: #383737;*/
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%);
  }
}

.external_links{
  @include align-items();
  flex-flow: wrap;
  padding: 3px;
  width: 100%;
  margin-top: 20px;
  gap: 10px;

  &_item{
    @include align-justify();
    width: calc(50% - 5px);
    height: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    clip-path: polygon(10px 0%, 100% 0%, calc(100% - 10px) 100%, 0% 100%);
    border: 1px solid transparent;

    &.google{
      background-color: #fff;

      &:hover{
        background-color: #efefef;
      }
    }

    &.facebook{
      background-color: #527bbe;

      &:hover{
        background-color: #365888;
      }
    }

    &_image{
      height: 35px;
      @include margin-end(10px);
      @include align-justify();

      & img{
        height: 26px;
      }
    }

    &_name{
      font-size: 0.9em;
      font-weight: 500;

      .google & {
        color: #000;
      }
    }
  }
}


@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}
.page {
  &.not-found {
    background: url("/www/images/bikes/back-moto.jpg") no-repeat bottom;
    .w-wrap {
      padding-top: 60px;
    }
    .promo {
      margin: 2rem;
      background: #fff;
      border: 1px solid #d90303;
      box-shadow: 2px 2px 5px rgba(0,0,0,.3);
      display: flex;
      flex-direction: column;
      font-size: .867em;
      padding: 10px;
      width: calc(100% - 4rem);
      .promo_subtitle {
        font-size: 1.1em;
        font-weight: 500;
        margin-bottom: 3px;
        text-align: center;
      }
    }
    .btn_more {
      max-width: 300px;
      margin: 50px auto;
    }
  }
}

@import './themes/default';
@import './mixin';

%scrollbar {
  &::-webkit-scrollbar {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $secondary_color;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
$main-font: "TTProstoSans";
$main-font_src: url('/fonts/TTProstoSans.woff') format('truetype');
$bold-font: "TTProstoSansBold";
$bold-font_src: url('/fonts/TTProstoSansBold.woff') format('truetype');
$secondary-font: "ProximaNova";
$secondary-font_src: url('/fonts/ProximaNova.woff') format('truetype');
$secondary-bold-font: "ProximaNovaBold";
$secondary-bold-font_src: url('/fonts/ProximaNovaBold.woff') format('truetype');
$rtl-font: "Arimo-Regular";
$rtl-font_src: url('/fonts/Arimo-Regular.woff2') format('woff2');

$positive_color: #006800;
$light_positive_color: #89b290;
$negative_color:  #990000;
$light_negative_color: #d77b5a;
$next_color: #007599;
$light_next_color: #12a4d2;

$secondary-color: #D90303;
$secondary-color-hover: gold;

@import '../../../styles/themes/default';
@import '../../../styles/mixin';

.roulette {
  &_data {
    height: 100%;
    width: 100%;
    @include align-justify();

    &_body {
      height: 100%;
      width: 100%;
      @include align-justify();

      & iframe {
        height: 100%;
        width: 100%;
        min-height: max(50vh, 500px);

        .mobile & {
          width: 100vw;
          min-height: 60vh;
        }
      }

      &.mail_stage {
        /*    background: url(/www/images/bikes/back-moto.jpg) no-repeat bottom; */
      }

      &_mail {
        @include flex-column;
        width: 100%;

        &_header{
          position: relative;
          display: block;

          &_sound{
            position: absolute;
            @include end(55px);
            top:20px;
            height: 30px;
            width: 30px;
            cursor: pointer;

            & img {
              height: 100%;
              width: 100%;
            }
          }

          &_exit {
            position: absolute;
            @include end(15px);
            top:20px;
            height: 30px;
            width: 30px;
            cursor: pointer;

            & img {
              height: 100%;
              width: 100%;
            }
          }
        }

        &_body {
          padding: 1em 2.5em;
          @include flex-column;
          width: 100%;
          background: url(/www/images/bikes/back-moto.jpg) no-repeat bottom;
          min-height: 280px;
        }

        &_error{
          flex-grow: 1;
          @include align-justify();
          @include padding-start(15px);
          @include padding-end(15px);
          margin-bottom: 15px;

          &_message{
            color: red;
            font-size: 1.5em;
          }
        }

        &_rules {
          @include flex-column;
          width: 100%;
          margin-bottom: 15px;

          &_header{
            text-align: start;
            font-weight: bold;
            margin-bottom: 12px;
          }

          &_items{
            font-size: 0.925em;
            @include flex-column;
            width: 100%;
          }

          &_item{
            @include flex-column;
            width: 100%;
            margin-bottom: 12px;
          }

          &_name{
            font-weight: bold;
            margin-bottom: 6px;
          }
          &_text{
            font-style: oblique;
            text-align: justify;
          }

/*          &_item {
            margin-bottom: 7px;
            width: 100%;
            @include align-items();
          }*/
        }

        &_input {
          @include flex-column;
          width: 100%;
          margin-bottom: 20px;
          align-items: center;

          &_label {
            font-size: .95em;
            font-weight: 500;
            margin-bottom: .5em;
          }

          & .input-item {
            &.error {
              & input {
                border: 1px solid #e00707;
                outline-color: #e00707;
              }
            }

            & input {
              @include padding-start(1rem);
              @include padding-end(1rem);
              border: 1px solid #e5e5e5;
              max-width: 350px;
              min-width: 350px;
              font-size: 1em;
              height: 50px;
              outline-color: #ccc;
              width: 100%;

            }
          }
        }

        &_footer {
          width: 100%;
          @include align-justify();
          min-height: 50px;

          &_button {
            max-width: 330px;
            width: 330px;
          }
        }
      }
    }
  }
}
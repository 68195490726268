@import '../styles/themes/default';
@import '../styles/mixin';

#root {
  /*background: url("/www/images/tmp_contact.jpg") center top;*/
}

.contacts {
  @include flex-column;
  position: relative;
  overflow: hidden;
  background: url("/www/images/back-contact.jpg") no-repeat;

  html[lang=he] & {
    background: url("/www/images/back-contact-he.jpg") no-repeat;
  }

  background-position: center top;
  background-color: #ffffff;

  &_wrap {
    @include flex-column();
    margin: 0 auto;

    & .title {
      margin: 0;

      .mobile & {
        margin: 0 auto;
      }
    }
  }

  &_header {
    @include column-align-items();
    background: $secondary-color;
    border: 1px solid #555;
    @include padding-start(10px);
    @include padding-end(10px);
    width: 100%;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
    margin-bottom: 1rem;
  }

  &_area {
    padding-top: 45px;
  }

  &_form {
    width: 75%;

    .mobile & {
      order: 2;
      width: 95%;
      margin: 0 auto;
      @include column-align-items();
    }

    &_layer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.95);
      z-index: 100;
      @include align-justify();
      transition: 1s ease-out;

      &.disable_layer {
        left: -1900px;
        right: unset;

      }
    }

    &_data {
      @include d-flex;
      height: 400px;
      flex-direction: column;
      padding-top: 30px;

      &_column {
        display: flex;
        position: relative;
      }

      &_item {
        @include flex-column;
        width: 100%;
        margin-bottom: 1.25rem;

        .input-phone input {
          direction: ltr;
        }

        .input-email input {
          unicode-bidi: plaintext;
        }

      }

      &_label {
        font-size: 0.95em;
        font-weight: 500;
        margin-bottom: 0.5em;

        .mobile & {
          font-size: 1.275rem;
        }
      }

      &_value {
        width: 100%;

        & .input-item {
          margin: 0 1.5rem 0 0;

          html[lang=he] & {
            margin: 0 0 0 1.5rem;
          }

          &.error {
            & input {
              border: 1px solid #e00707;
              outline-color: #e00707;
            }

            & textarea {
              border: 1px solid #e00707;
              outline-color: #e00707;
            }
          }

          & input {
            height: 50px;
            width: 100%;
            font-size: 1em;
            border: 1px solid #e5e5e5;
            @include padding-start(1rem);
            @include padding-end(1rem);
            outline-color: #ccc;

            &::placeholder {
              font-size: 0.85rem;
              color: darkgray;
            }
          }

          & textarea {
            width: 100%;
            font-size: 1em;
            border: 1px solid #e5e5e5;
            @include padding-start(1rem);
            @include padding-end(1rem);
            outline-color: #ccc;

            &::placeholder {
              font-size: 0.9rem;
              color: darkgray;
            }
          }
        }
      }
    }

    & .btn_more {
      max-width: 330px;
    }
  }

  &_subheader {
    height: 40px;
    @include align-items();
    font-size: 20px;
    font-weight: 700;
    /*text-shadow: 1px 1px 1px rgb(0 0 0 / 30%), 0px -1px 3px rgb(255 255 255 / 30%);*/

    .mobile & {
      font-size: 1.75rem;
    }
  }

  &_data {
    @include flex-column;

    .mobile & {
      order: 1;
      width: 100%;
      align-items: center;
      margin: 10px 20px;
    }

    &_area {
      padding-top: 45px;
      width: 100%;
      @include column-justify();
      height: calc(100% - 40px);
    }

    &_items {
      @include flex-column;
      width: 100%;

      &_row {
        @include flex-column;
        width: 100%;
        margin-bottom: 1.5rem;

        & div {
          padding: 5px 0;
        }

        &_name {
          font-size: 14px;
          font-weight: 600;
        }

        &_value {
          font-size: 18px;
          font-weight: 400;

          span {
            unicode-bidi: plaintext;
          }

          .mobile & {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  &_map {
    @include flex-column;
    width: 100%;
    background: rgba(250, 250, 250, 0.7);
    padding-bottom: 30px;

    &_area {
      @include margin-end(1rem);
      width: 100%;
      background: #fff;
      box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
      min-height: 200px;

      .mobile & {
        @include column-align-items();
      }

      & iframe {
        width: 100%;
        min-height: 500px;
      }
    }
  }
}
.fixedMsgHolder {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 8px;
  z-index: 100;
}

.hide #recentBookings{
  display: none;
}
.mobile{
  #recentBookings{
    max-width: unset;
  }
  .panel-yellow {
    font-size: 28px;
    max-width: unset;
    line-height: 40px;
  }
}
#recentBookings{
  max-width: 400px;
}

.panel-yellow {
  background-color: #fef6c0;
  border-color: #fce463;
  border-style: solid;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 15px;
  max-width: 400px;
  color: #3c3201;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  position: relative;
  line-height: 20px;
  .close{
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
}

.alert {
  padding: 25px;

  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
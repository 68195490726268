@import "../../styles/mixin";

.photos_gallery{
  @include align-items();
  width: 100%;
  height: 100%;

  & img {
    cursor: pointer;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  @include align-justify();
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include filter-contrast();
  @include transition(filter .6s steps(12, end), transform .4s cubic-bezier(.785, .135, .15, .860));
  @include transform(translateZ(0) scale(.97));
  @include transform-origin(50% 50%);
}

.swiper-slide {
  width: 60%;

  &:hover{
    & img {
      @include transform(translateZ(0) scale(1));
      @include filter-contrast(1.3);
    }
  }
}

.swiper-slide:nth-child(2n) {
  width: 40%;
}

.swiper-slide:nth-child(3n) {
  width: 20%;
}

.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 2rem !important;
  font-weight: bold;
}

.swiper-button-next, .swiper-button-prev{
  color: #d9534f !important;
}

.swiper-pagination-bullet-active{
  background: #d9534f !important;
}

@import "../../styles/mixin";
@import "../../styles/themes/default";

.date-select {
  @include justify(flex-end);
  flex-direction: column;

  .mobile & {
    width: 95%;
    margin: 0 auto 1.5em;
    order: 1;

    & .heading {
      font-size: 1.75em !important;
    }
  }

  .date-select-item {
    @include padding-start(1rem);
    flex-grow: 1;
    max-width: 300px;
    min-width: 300px;
    /*font-weight: 700;*/
    font-size: 16px;

    .mobile & {
      max-width: max-content;
      min-width: 100%;
      width: 100%;
      @include padding-start(0);
    }

    .order-calc {
      @include flex-column;
      background: #fff;
      /*margin-bottom: 0.75rem;
      box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);*/
      padding: 1em;
      border: 1px solid #D9D9D9;

      & .heading {
        padding: 0.5rem;
        margin-bottom: 15px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;

        .mobile & {
          font-size: 1.75em;
        }
      }

      /*& .heading {
        padding: 0.5rem;
        font-size: 1.5em;
        margin-bottom: 15px;
        font-weight: 500;
        text-align: center;
        border-bottom: 1px solid #eee;
        background: rgba(249, 249, 249, 0.5);
      }*/

      & .total-checked {
        @include flex-column;
        width: 100%;

        &-payment {
          @include align-justify(center, space-between);
          width: 100%;
          padding: 0.5rem 0.75rem;

          &_name {
            font-weight: 600;
          }
        }

        &-date {
          @include flex-column;
          padding: 0.5rem 0.75rem;
          width: 100%;
          border-bottom: 1px solid #d9d9d9;

          &_item {
            @include align-justify(center, space-between);
            height: 1.7em;
            width: 100%;

            &_name {
              font-weight: 600;
            }

            &_date {
              font-weight: 600;
            }
          }
        }

        &-rent {
          @include flex-column;
          padding: 0.5rem 0.75rem;
          width: 100%;
          border-bottom: 1px solid #d9d9d9;

          &_item {
            @include align-justify(center, space-between);
            width: 100%;
            height: 1.7em;
          }
        }

        &-passenger {
          @include flex-column;
          padding: 0.5rem 0.75rem;
          width: 100%;

          &_item {
            @include align-justify(center, space-between);
            width: 100%;
          }
        }

        &-hotels {
          @include flex-column;
          padding: 0.5rem 0.75rem;
          width: 100%;

          &_item {
            @include align-justify(center, space-between);
            width: 100%;
          }
        }

        &-accessories {
          @include flex-column;
          padding: 0.5rem 0.75rem;
          width: 100%;
          border-bottom: 1px solid #d9d9d9;

          &_item {
            @include align-justify(center, space-between);
            width: 100%;
            margin-bottom: 5px;

            &_name {
              @include align-items();

              &_count {
                margin-inline-start: 5px;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &-extras {
          @include flex-column;
          padding: 0.5rem 0.75rem;
          width: 100%;
          border-bottom: 1px solid #d9d9d9;
          &_item {
            @include align-justify(center, space-between);
            width: 100%;

            &_name {
              @include align-items();
            }
          }
        }

        &-block {
          @include flex-column;
          padding: 0.5rem 0.75rem;
          width: 100%;
          border-bottom: 1px solid #d9d9d9;

          &_item {
            @include align-justify(center, space-between);
            width: 100%;

            &.margin_bottom{
              margin-bottom: 5px;
            }

            &.middle_height {
              height: 1.7em;
            }

            &.bold{
              font-weight: 600;
            }

            &_name {
              @include align-items();
            }
          }
        }

        & .sold_out_calc {
          margin: 3rem auto;
          font-size: 1.2rem;
          font-weight: 500;
          max-width: 200px;
        }
      }
    }
  }
}

.total-amount {
  @include align-justify(center, space-between);
  width: 100%;
  padding: 1.5rem 0.75rem;
  min-height: 1.7em;

  &_name {
    font-weight: 600;
  }

  &_value {
    font-weight: 600;
  }

  span {
    padding: 5px;
  }

  &_note {
    font-size: 0.85rem;
    font-style: italic;
    display: none;
  }

  &_block{
    @include flex-column;;
    width: 100%;

    &_item{
      @include align-justify(center, space-between);
      width: 100%;
      padding: 1.5rem 0.75rem;

      &.middle_height {
        height: 1.7em;
      }

      &.bold{
        font-weight: 600;
      }

      &_name {
        font-weight: 600;
      }

      &_value {
        font-weight: 600;
      }

      span {
        padding: 5px;
      }
    }
  }
}

.total-promo {
  @include flex-column;
  border-top: 1px solid #d9d9d9;
  padding: 0.5rem 0;
  width: 100%;

  &_text {
    margin-top: 0.5em;
    font-size: 0.75em;
    font-style: italic;
    text-indent: 0.5em;

    &.error_text {
      color: red;
      font-size: 0.85em;
      font-style: normal;
    }
  }

  &_area {
    width: 100%;
    @include align-items();
    position: relative;
    height: 3rem;

    &_input {
      @include align-items();
      height: 100%;
      width: 100%;

      & svg {
        color: green;
        font-size: 1.85rem;
        position: absolute;
        left: 0.35em;
      }

      & .input-item {
        height: 100%;
        width: 100%;
        border: 1px solid #000;
        transition: 0.3s;

        &.error {
          animation: blinkingBorder 1s infinite;
        }

        &.discount_code {
          border: 2px solid green;

          & input {
            text-indent: 2.45em;
            color: green;
            font-weight: 600;
          }
        }
      }

      & input {
        height: 100%;
        width: 100%;
        text-indent: 0.5em;
        border: unset;
        outline: unset;

        &:disabled {
          user-select: none;
        }
      }
    }

    &_button {
      position: absolute;
      @include end(0.35em);

      & .btn {
        font-size: 0.65em;
      }
    }
  }
}

.checkout {
  @include column-justify(space-between);
  flex-grow: 1;


  .low-available{
    position: absolute;
    top: -8px;

    color: red;
    font-weight: bolder;
    width: 60%;
    text-align: center;
  }
  .mobile & {
    width: 95%;
    margin: 0 auto;
    order: 2;
  }

  &_page {
    @include justify(space-between);
    width: 100%;

    .mobile & {
      overflow: hidden;
      @include flex-column;
    }
  }

  .accessor {
    @include flex-column;
    background: #fff;

    &.payment {
      .checkbox-wrapper-12 .label {
        width: 0;
      }
    }

    /*    margin-bottom: 0.75rem;
        box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);*/

    .mobile & {
      margin-bottom: 0;
    }

    &_size {
      @include flex-column;
      background: #fff;
      margin-bottom: 0.75rem;
      /*      box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);*/

      & .heading {
        padding: 0.5rem;
        margin-bottom: 15px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;

        .mobile & {
          font-size: 1.75em;
        }
      }

      &_items {
        @include flex-column;
        width: 100%;

        &_row {
          @include flex-column;
          width: 100%;

          &_name {
            background-color: #fbfbfb;
            padding: 0.5rem 2rem;
          }

          &_sizes {
            @include flex-column;
            width: 100%;

            &_item {
              @include align-justify(center, space-between);
              padding: 0.3rem 1.5rem;

              &:hover {
                background-color: #fcfcfc;
              }

              & select {
                min-width: 60px;
                padding: 0.2rem 0.65rem;
              }
            }
          }
        }
      }
    }

    &.canOpen{

      & .heading {
        cursor: pointer;
      }
    }

    .heading {
      padding: 0.5rem;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      width: 100%;


      &_opener{
        position: absolute;
        @include end(.5rem);
        top: calc(50% - 0.55rem);
        cursor: pointer;
      }

      .mobile & {
        font-size: 1.75em;
      }
    }

    &.closed {
      & .rental_values {
        padding: 0 0.75em;
        height: 0;
        overflow: hidden;
      }
    }

    & .rental_values {
      @include flex-column;
      padding: 0.5em 0.75em;
      height: max-content;
      transition: 0.5s;

      &.checkout_hotels {
        flex-direction: column;

        & .checkbox-wrapper-12 {
          /*flex-direction: column;
          height: 100px;
          text-align: center;
          border: 1px solid #ccc;*/
          flex-direction: row !important;

          .label {
            width: auto;
          }
        }
      }
    }
  }

  .input-item {
    height: 3em;
    /*width: calc(100% - 3rem);**/
    margin: 0 0 0.75rem;

    input {
      height: 100%;
      width: 100%;
      font-size: 1em;
      border: 1px solid #ccc;
      padding: 0 1rem;
      outline-color: #ccc;

      &::placeholder {
        color: #a5a5a5;
      }
    }

    select {
      height: 100%;
      width: 100%;
      font-size: 1em;
      border: 1px solid #ccc;
      @include padding-start(1rem);
      outline-color: #ccc;

      &::placeholder {
        color: #a5a5a5;
      }
    }

    &.error {
      input {
        border: red 1px solid;
        background: oldlace;
      }
      select {
        border: red 1px solid;
        background: oldlace;
      }
    }
  }

  .selected-block {
    display: flex;
    width: 100%;
    @include justify(flex-start);
    flex-direction: column;

    .mobile & {
      @include flex-wrap();

    }

    &.one {

    }
  }

  & .main-data {
    width: 100%;
    @include margin-end(1.5rem);
    padding: 1rem;
    @include flex-column;
    background: #fff;
    margin-bottom: 0.75rem;
    /*box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);*/
    /*flex-grow: 1;*/
    border: 1px solid #D9D9D9;

    .mobile & {
      width: 100%;
      @include margin-end(0);
      max-width: 100%;
      padding: 0.75rem;
    }
  }

  & .note-data{
    @include flex-column;
    padding: 0 1.75rem;
    font-size: 0.85em;
  }

  .date-box {
    width: 60%;
    padding: 1.25rem;
    margin: 0 auto 1.875rem;
    /*background: $secondary-color;*/
    border: 1px solid #ccc;
    /*box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);*/

    .mobile & {
      width: 95%;
      margin-bottom: 4rem;
    }

    &-group {
      @include align-items();
      @include flex-wrap();
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      margin-bottom: 0;
    }

    &-label {
      font-weight: 700;
      font-size: 1.125rem;
      flex: 0 0 calc(100% / 3);
      max-width: calc(100% / 3);
      margin-right: 0;
      text-transform: none;
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      .mobile & {
        font-size: 1.5rem;
      }
    }

    &-value {
      flex: 0 0 calc(100% * 2 / 3);
      max-width: calc(100% * 2 / 3);
      margin-right: 0;
      text-transform: none;
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      & select {
        text-indent: 1px;
        font-size: 1.125rem;
        color: #555;
        box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
        height: 2.8125rem;
        line-height: 1.25;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 0;
        -moz-appearance: none;
        display: inline-block;
        max-width: 100%;
        padding: 0.75rem 1.75rem 0.75rem 0.75rem;

        .mobile & {
          font-size: 1.25rem;
        }
      }
    }
  }

  .booking-block {
    @include align-justify();
    height: 80px;
    /*    margin-top: 50px;*/

    &.two {
      text-align: center;

      & .btn {
        display: inline-block;
        margin-right: 40px;
        margin-left: 40px;
      }
    }

    & .btn {
      .mobile & {
        font-size: 1.75rem;
        padding: 0.75rem 1.25rem;
        line-height: 1.75;
      }
    }

    button {
      margin: 0 auto;
      display: block;
      padding: 15px;
      font-weight: bolder;
      border: 2px solid;
      cursor: pointer;

      &:hover {
        color: #330dee;
        border-color: #330dee;
        font-size: 110%;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
      }
    }
  }

  .input-date {
    display: none;
  }


  & .personal_info {
    @include flex-row;
    @include flex-wrap;
    background: #fff;
    margin-bottom: 0.75rem;
    /*    box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);*/

    &_subrow{
      @include align-items();
      padding-top: 10px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 15px;
      width: 100%;

      &_item{
        font-weight: bold;
        font-size: 1em;

        &.tab_item{
          border: 1px solid #ccc;
          padding: 10px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background: #fcfcfc;
          margin-bottom: -1px;
          cursor: pointer;
          &.active {
            border-bottom-color: transparent;
          }
        }

        &.link_item{
          text-decoration: underline;
          cursor: pointer;
          font-weight: normal;
          font-size: 0.85em;
          flex-grow: 1;
          @include align-justify(center,flex-end);
          @include margin-end(5px);
        }
      }
    }

    & .heading {
      padding: 0.5rem;
      margin-bottom: 15px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      width: 100%;

      .mobile & {
        font-size: 1.75em;
      }
    }

    &_item {
      @include margin-end(3%);
      width: calc(100% / 2 - 3%);

      &.note_item{
        @include margin-end(3%);
        width: calc(100% );
      }

      &_label {
        font-size: 0.95em;
        font-weight: 500;
        margin-bottom: 0.5em;
        @include align-items();

        &_warning{
          @include margin-start(10px);
          font-style: oblique;
          text-align: justify;
          flex-grow: 1;
          @include justify(flex-end);
          color: $secondary-color;
        }
      }
      &_error {
        font-size: 0.85em;
        font-weight: 500;
       /* margin-bottom: 0.35em;*/
        color: red;
      }
    }
  }

  &_bikes {
    @include flex-column;
    width: 100%;

    & .heading {
      padding: 0.5rem;
      margin-bottom: 15px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;

      .mobile & {
        font-size: 1.75em;
      }
    }

    &_header {
      @include align-items();
      font-size: 1.1rem;
      font-weight: 500;
      padding: 0 1rem 1.5rem !important;
      border-bottom: 2px solid #eee;
    }

    &_rows {
      display: flex;
      flex-wrap: wrap;
    }

    &_row {
      padding: 0.5rem 1rem !important;
      @include align-items();
      height: max-content !important;
      cursor: pointer;
      flex-direction: column;
      position: relative;
      @include margin-end(0.5rem);

      &_name {
        color: #000;
      }

      &:hover {
        color: #d90303;
      }

      &.checked_bike {
        font-weight: 600;
        border: 1px solid #d5d5d5;
        box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
      }

      &_value {
        width: 18px;
      }

      &_image {
        width: 175px;
        transition: 0.25s;
        overflow: hidden;

        &:hover {
          & img {
            @include transform(translateZ(0) scale(1.05));
            @include filter-contrast(1.3);
          }
        }

        & img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          @include filter-contrast();
          @include transition(filter .6s steps(12, end), transform .4s cubic-bezier(.785, .135, .15, .860));
          @include transform(translateZ(0) scale(1));
          @include transform-origin(50% 50%);
          border: 1px solid #f5f5f5;
        }
      }

      &_count {
        background: #d83737;
        color: white;
        position: absolute;
        bottom: 0;
        @include end(0);
        padding: 5px;
        margin: 0 5px;
        font-style: normal;

        html[lang=he] & {
          unicode-bidi: plaintext;
        }

        .tour_book_checkout & {
          margin: 0;
        }
      }

      &_name {

      }

      &_price {
        width: 200px;
      }
    }

    & .sold_out_bikes {
      margin: 3rem auto;
      font-size: 1.2rem;
      font-weight: 500;
      max-width: 200px;
    }
  }
  &_own_bike{
    padding: 1rem 0.75rem;
  }
  &_passenger {
    padding: 1rem 0.75rem;
    margin-top: 2rem;


    & .value {
      font-weight: 500;
    }
  }
}

.order {
  &_data {
    @include column-align-items();
    padding: 1.5rem;

    .mobile & {
      padding: 3.5rem;
    }

    & h2 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      .mobile & {
        font-size: 2.5rem;
        margin-bottom: 7.5rem;
      }
    }

    & h4 {
      margin-top: 2rem;
      margin-bottom: 2rem;

      .mobile & {
        font-size: 2.5rem;
        margin-bottom: 5rem;
      }
    }
  }

  &_footer {
    margin-bottom: 1rem;

    .mobile & {
      margin-bottom: 2.5rem;

      & .btn {
        font-size: 2rem;
      }
    }
  }
}

.for-request {
  color: red;
  font-size: 1rem;
}

@keyframes blinkingBorder {
  50% {
    border: 3px solid red;
  }
}

.select-type-layer{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  .btn-panel {
    display: flex;
    height: 100%;
    justify-content: space-evenly;
    width: max-content;
    flex-direction: row;
    margin: 0 auto;
  }
}

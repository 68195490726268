@import "../styles/mixin";

.home {
  @include flex-column;
  width: 100%;

  &_top {
    width: 100%;
    background-clip: padding-box;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 2px 3px 6px rgb(0 0 0 / 50%);
    text-transform: uppercase;
    height: 660px;
    position: relative;
    &_right, &_left {
      width: 100%;
      height: 100%;
      position: absolute;
/*      transition: 1.5s;*/
   }
    &_right {
      background: url("/www/images/home/home_2.jpg") no-repeat center;
      z-index: 1;
    }
    &_left {
      background: linear-gradient(0deg, rgba(20, 20, 20, 0.59), rgba(20, 20, 20, 0.59)), url("/www/images/home/home_1.jpg") no-repeat center;
      clip-path: polygon(0% 0%, 46% 0%, 59% 100%, 0% 100%);
      filter: grayscale(1);
      z-index: 2;
      &.hover{
        background: url("/www/images/home/home_1.jpg") no-repeat center;
        filter: none;
        & + div.home_top_right {
          filter: grayscale(1);
        }
      }
    }
    &_center {
      position: absolute;
      width: 100%;
      height: 100%;
      color: white;
      pointer-events: none;
      z-index: 3;
    }
    &_title_wrap {
      width: 45%;
      height: 460px;
    }
    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #fff;
      text-shadow: 0px 0px 40px #000, 0px 0px 20px #000, 2px 2px 0 #000;
      text-transform: uppercase;
      & article {
        display: flex;
        flex-direction: column;
        color: #fff;
        font-weight: 900;
        letter-spacing: 1px;
        line-height: 1.2;
        & h1 {
          font-size: 28px;
          font-weight: 300;
          padding-top: 50px;
          margin: 25px 0;
          line-height: 44px;
        }
        & h2 {
          font-size: 38px;
          font-weight: 700;
          opacity: 1;
          padding-top: 0;
          margin-top: 0;
        }
      }
    }
    &_buttons {
      &_wrap {
        height: 160px;
        display: flex;
        justify-content: space-between;
      }
      &_item {
        width: 28%;
        color: white;
        text-transform: uppercase;
        &.align_end .btn_more{
          margin-left: auto;
          html[lang=he] & {
            margin-left: 0;
            margin-right: auto;
          }
        }
        .btn_more {
          pointer-events: all;
        }
      }
      &_title {
        font-size: 32px;
        padding-bottom: 20px;
        .align_start & {
          text-align: start;
        }
        .align_end & {
          text-align: end;
        }
      }
    }
  }

  &_center {
    height: 800px;
    background: url("/www/images/home/back-tours.jpg") no-repeat;
    background-position: center;
    .mobile & {
      height: auto;
    }
    &_wrap {
      display: flex;
      flex-wrap: wrap;
    }
    &_item {
      width: 50%;
      height: 400px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      justify-content: space-evenly;
      margin: 5px auto;

      .mobile & {
        width: 100%;
      }
      &_3 {
        order: 3;
        .mobile & {
          order: 4;
        }
      }
      &_4 {
        order: 4;
        .mobile & {
          order: 3;
        }
      }
      & h1 {
        font-size: 32px;
        font-weight: 700;
        text-transform: uppercase;
      }
      & p {
        font-size: 18px;
        margin: 0 5px;
      }
      &_tour {
        background-size: 99% 99%;
        background-position: center;
        background-repeat: no-repeat;
        width: 600px;
        height: 400px;
        border: 1px solid red;
        transition: transform 1.5s;

        &.tour1 {
          background-image: url("/www/images/home/tour_1.jpg");
          transform: perspective(31px) rotateY(181deg) translate(-30px, 0px) scale(0.85);
          &:hover {
            transform: rotateY(180deg);
          }
        }
        &.tour2 {
          background-image: url("/www/images/home/tour_2.jpg");
          transform: perspective(31px) rotateY(179deg) translate(50px, 0px) scale(0.85);
          &:hover {
            transform: rotateY(180deg);
          }
        }
      }
    }

  }

  &_slider {
    max-width: 95%;
    width: 95%;
    margin: 0 auto;
    height: 50vh;
    position: relative;
    z-index: 1;
    background-clip: padding-box;
    box-shadow: 2px 3px 6px rgb(0 0 0 / 50%);

    &_area {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
    }

    &_data {
      @include align-justify(flex-end, space-between);
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    &_title {
      margin-left: 2rem;
      margin-bottom: 2rem;
      color: #fff;
      text-shadow: 0px 0px 40px #000, 0px 0px 20px #000, 2px 2px 0 #000;

      & article {
        @include flex-column;
        color: #fff;
        font-weight: 900;
        letter-spacing: 1px;
        line-height: 1.2;

        & h2 {
          font-size: 2.8rem;
        }

        & small {
          font-size: 1.25rem;
          font-weight: 400;
        }
      }
    }

    &_booking {
      @include d-flex;
      align-self: center;
      position: relative;
      z-index: 1;
      flex: 0 0 auto;
      margin-right: -22.5px;

      &:before {
        position: absolute;
        content: "";
        display: block;
        pointer-events: none;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxIDEiIGZpbGw9IiNBN0E3QTciPjxwYXRoIGQ9Ik0wIDFsMS0xdjF6Ii8+PC9zdmc+) center no-repeat;
        background-size: cover;
        transform: rotate(90deg);
        right: 0;
        top: auto;
        left: auto;
        width: 22.5px;
        height: 22.5px;
        bottom: 100%;
      }

      &:after {
        content: "";
        border: solid 0.3529411765rem #fff;
        border-right: none;
        position: absolute;
        z-index: -1;
        pointer-events: none;
        top: -0.8823529412rem;
        left: -0.8823529412rem;
        bottom: -0.8823529412rem;
        right: 22.5px;
      }

      &_area {
        @include column-align-items(center);
        padding: 1.2135rem;
        background-color: #ffe01b !important;
        color: rgba(0, 0, 0, .7);

        &:before {
          position: absolute;
          content: "";
          display: block;
          pointer-events: none;
          background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxIDEiIGZpbGw9IiNBN0E3QTciPjxwYXRoIGQ9Ik0wIDFsMS0xdjF6Ii8+PC9zdmc+) center no-repeat;
          background-size: cover;
          left: auto;
          bottom: auto;
          right: 0;
          width: 22.5px;
          height: 22.5px;
          top: 100%;
          transform: rotate(180deg);
        }
      }

      &_header {
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.2;
        text-align: center;
        width: 100%;
        margin-bottom: 1.25rem;
      }
    }

    & .swiper-slide {
      max-height: 50vh;
      height: 50vh;

      & img {
        transform: translateZ(0) scale(1);
      }

      & :hover img {
        transform: translateZ(0) scale(1);
        filter: contrast(1);
      }
    }
  }

  &_main {
    @include flex-column;
    width: 100%;
    margin-top: 6.4705882353rem;
    margin-bottom: 5rem;

    &_wrap {
      @include align-items();
      position: relative;
      background: linear-gradient(-3deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) calc(0% + 7rem), #fff calc(0% + 7rem), #fff calc(100% - 7rem), rgba(255, 255, 255, 0) calc(100% - 7rem), rgba(255, 255, 255, 0) 100%);
      padding: 0 2rem;
    }

    &_body {
      padding: 5rem 5%;
      @include margin-start(2rem);
      @include column-justify();
      -webkit-transform: rotate(-4deg);
      transform: rotate(-4deg);
      flex: 0.8 1 auto;

      & .header {
        padding: 3rem 3.5rem;
        color: #333;
        background: #ffe01b;
        display: table;
        -webkit-clip-path: polygon(2rem 1.5rem, calc(100% - 2.5rem) 0%, calc(100% - 0.5rem) calc(100% - 1rem), 1rem calc(100% - 1.5rem));
        clip-path: polygon(2rem 1.5rem, calc(100% - 2.5rem) 0%, calc(100% - 0.5rem) calc(100% - 1rem), 1rem calc(100% - 1.5rem));
        -webkit-transform: rotate(-.5deg);
        transform: rotate(-.5deg);
        position: relative;
        z-index: 10;
        margin-top: -1rem;
        margin-left: -4rem;
        margin-bottom: 1rem;
        width: fit-content;

        & h1 {
          font-weight: 600;
          font-size: 3rem;
        }

        & h2 {
          font-weight: 500;
          font-size: 2rem;
        }
      }
    }

    &_photo {
      margin-top: -145px;
      margin-bottom: -75px;
      flex: 0 0 30%;
      width: 475px;
      max-height: 475px;
      margin-right: 2rem;
      max-width: 530px;

      & img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        border: 10px solid #fff;
        box-shadow: 2px 3px 6px rgb(0 0 0 / 50%);
        cursor: pointer;
      }
    }
  }

  &_moto {
    height: 736px;
    /*background: url("/www/images/home/Honda-NT1100-DCT-back.jpg") no-repeat;*/
    background: url("/www/images/home/Honda-NC750X-back.jpg") no-repeat;
    background-position: center;
    &_wrap {
      color: white;
      padding: 75px 20px;
      display: flex;
      justify-content: space-between;
      position: relative;
    }
    &_info {
      width: 50%;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        text-transform: uppercase;
        background: linear-gradient(76.17deg, #D90303 0%, #D93838 110.42%);
        width: max-content;
        padding: 10px 40px 10px 20px;
        clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
        z-index: 1;
      }

      &_model {
        color: black;
        font-size: 40px;
        font-weight: 700;
      }
    }
    &_price {
      width: 50%;
      height: 120px;
      text-align: end;
      margin-top: 85px;

      &_amount {
        font-size: 40px;
        font-weight: 700;
        color: #D90303;
      }

      &_more {
        margin: 8px 0;

        & .btn_more {
          margin-left: auto;
          max-width: 240px;

          html[lang=he] & {
            margin-left: unset;
            margin-right: auto;
          }
        }
      }
    }
  }
}

.mobile {
  .home {
    &_top {
      height: max-content;
      &_title {
        text-shadow: none;
        padding-bottom: 20px;

        article {
          color: #000000;
          @include justify();
          @include padding-end(15px);
          @include padding-start(15px);
          text-align: center;

          h1 {
            padding-top: 20px;
            text-align: center;
          }
        }
      }
      &_buttons_title {
        color: white;
      }
      &_item {
        padding-top: 46%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &_tour {
          background-image: url(/www/images/home/home1.jpg);
          align-items: flex-start;
        }
        &_bike {
          background-image: url(/www/images/home/home2.jpg);
          align-items: flex-end;
          .home_top_item_row {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }
        &_row {
          margin: 10px;
        }
      }
    }
  }
}
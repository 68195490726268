@import "../../../styles/mixin";
@import "../../../styles/themes/default";

.bike_checkout {
  @include flex-column;
  width: 100%;
  min-height: 500px;

  &_type {
    @include align-justify();
    padding-top: 20px;

    & .btn-panel {
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: space-evenly;
      margin: 0 auto;
      width: max-content;
    }
  }

  & .checkout_page{
    margin-top: 20px;
  }

  &_layer{
    & h2 {
      .mobile & {
        font-size: 20px;
      }
    }
  }

  &_date {
    @include margin-end(1rem);

    .mobile & {
      width: 100%;
      @include margin-end(0);
      margin-bottom: 1rem;
    }

    &_block{
      display: flex;
      flex-direction: row !important;

      .mobile & {
        flex-wrap: wrap;
      }
    }
  }

  &_time{
    @include align-justify();
    flex-grow: 1;

    & .bike_checkout_layer{
      .mobile &{
        width: 100%;
      }
    }

    & .time_selector{
      width: 450px;

      .mobile & {
        width: 100%;
      }
    }
  }
}
@import '../../styles/mixin';
@import '../../styles/themes/default';

.edit {
    &_profile{
      @include flex-column;
      width: 100%;

      &_section{
        margin-bottom: 8px;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
      }

      &_message{
        color: red;
        padding-bottom: 8px;

        &.good_message{
          color: #5cb85c;
        }
      }

      &_header{
        @include align-justify(center,space-between);
        cursor: pointer;
        outline: none;
        padding: 24px;
        flex-grow: 1;
        font-size: 18px;
        color: #221f1f;

        .mobile & {
          font-size: 2.25rem;
        }
      }

      &_body{
        @include padding-start(48px);
        @include padding-end(24px);
        padding-bottom: 24px;
      }

      &_list{
        display: grid;
        column-gap: 24px;
        grid-template-columns: repeat(2,1fr);

        .mobile & {
          grid-template-columns: repeat(1,1fr);
        }

        &_item{
          margin-bottom: 24px;

          .mobile & {
            margin-bottom: 32px;
          }
        }
      }

      &_label{
        display: block;
        margin-bottom: 8px;
        color: #797878;
        font-size: .95em;

        .mobile & {
          font-size: 2rem;
        }

        html[lang=he] & {
          unicode-bidi: plaintext;
        }
      }

      &_value{

        &.error {
          & input, & select {
            border: 1px solid red;
          }
        }

        & input, & select {
 /*         -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;*/
          border: 1px solid #d2d2d2;
       /*   border-radius: 8px;*/
          outline: none;
          transition: border .2s ease-in-out;
          font-size: 14px;
          background-color: #fff;
          box-sizing: border-box;
          padding-left: 12px;
          padding-right: 12px;
          height: 40px;
          width: 100%;

          .mobile & {
            font-size: 1.65rem;
            height: 4.5rem;
          }
        }
      }

      &_footer{
        @include align-items();
      }

      &_button{
        font-size: 16px;
        min-width: 80px;
        height: 36px;

        .mobile & {
          font-size: 2rem;
          height: 3.75rem;
        }
      }
    }
}
@import "../styles/mixin";


.faq {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background-image: url("/www/images/bike_bg.jpg");
  background-size: cover;
  background-position: center;

  &_wrap{
    @include flex-column;
    min-height: inherit;
    margin: 0 auto;
    background: white;
    padding: 1.5rem 3.5rem;
    font-size: 1.5em;

    h1.title {
      margin-bottom: 30px;
    }

    h2 {
      font-size: 1.3rem;
      margin: 10px 20px;

      .mobile & {
        font-size: 2rem;
      }
    }

    .mobile & {
      min-height: max-content;
      max-width: 98%;
      min-width: 98%;
      @include flex-wrap();
      padding: 1.5rem 0.5rem;
    }
  }

  &_menu{
    @include flex-column;
    width: 25%;
    @include margin-end(2%);
    background: gold;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
    min-height: 200px;
    border: 1px solid #bbb;
    padding: 0.5rem  1rem;

    .mobile &{
      width: 100%;
      min-height: max-content;
      margin-bottom: 2rem;
      @include margin-end(0);
    }

    &_list{
      @include flex-column;
      margin: 1em 0 0;
      padding: 0.5em 0 0 1.25em;
      border-width: 2px 0 0 0;

      &_item{
        cursor: pointer;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
        text-shadow: 1px 1px 1px rgb(0 0 0 / 30%), 0px -1px 4px rgb(255 255 255 / 30%);
        font-weight: 500;

        .mobile &{
          @include margin-start(2rem);
          font-size: 2.25rem;
          text-shadow: unset;
          margin-bottom: 2rem;
        }
      }
    }
  }

  &_list{
    @include flex-column;
    background: #fff;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
    min-height: 200px;
    position: relative;
    overflow: hidden;
    padding: 1rem 1.5rem;

    .mobile & {
      width: 100%;
      gap: 1.5rem;
    }

    &_item{
      @include flex-column;
      width: 100%;
      outline: none;
    }

    &_header{
      font-size: 1.5rem;
      color: #f1c900;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .mobile & {
        font-size: 2.25rem;
        color: #dcb601;
      }
    }

    &_questions{
      @include flex-column;
      width: 100%;
      padding: 0.5rem 0.75rem;
      cursor: pointer;

      .mobile & {
        gap: 0.5rem;
      }

      &_item{
        @include flex-column;
        width: 100%;
        border-bottom: 2px solid #e5e5e5;
        background: #f1f1f1;

        &.expanded_answer{
          border-bottom: 2px solid #8d8d8d;
        }

        &:last-child{
          border-bottom: unset;
        }

        &_row{
          min-height: 40px;
          @include align-items();
          @include padding-start(0.75rem);
          @include padding-end(0.75rem);
          width: 100%;
          line-height: 40px;

          .mobile & {
            min-height: 60px;
            line-height: 60px;
          }
        }

        &_question{
          @include align-items();
          font-weight: 500;
          font-size: 1.2rem;
          flex-grow: 1;

          .expanded_answer & {
            font-weight: bold;
            padding-bottom: 10px;
          }

          .mobile & {
            font-size: 1.8rem;
          }
        }

        &_expander{
          transition: 0.3s;
          height: 25px;
          .mobile & {
            height: 50px;
          }

          & svg {
            width: 20px;
            height: 20px;
            fill: #848484;
            .mobile & {
              width: 40px;
              height: 40px;
              font-size: 1.5rem;
            }
          }

          .expanded_answer & {
            transform: rotate(180deg);
            & svg {
              fill: #000;
            }
          }
        }

        &_answer{
          height: 0;
          opacity: 0;
          display: none;
          transition: 0.3s;
          padding-bottom: 1rem;
          font-size: 1.1rem;

          .expanded_answer & {
            opacity: 1;
            display: block;
            height: min-content;
          }

          .mobile & {
            font-size: 1.75rem;
          }
        }
      }
    }
  }
}
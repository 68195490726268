@import "../styles/mixin";
@import "../styles/themes/default";

.packages {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: url("/www/images/bikes/back-moto.jpg") no-repeat bottom;
  padding-bottom: 30px;

  &_wrap {
    @include flex-column();
    /*    max-width: 1200px;
       min-width: 1200px;
    max-width: 95%;
    min-width: 95%;
    margin: 0 auto;*/

    &_header {
      @include column-align-items();
      background: $secondary-color;
      border: 1px solid #555;
      @include padding-start(10px);
      @include padding-end(10px);
      width: 100%;
      box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
      margin-bottom: 1rem;

      h2 {
        @include column-align-items();
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;

        .mobile & {

          font-size: 2.2rem;
        }
      }
    }
  }

  &_area {
    @include flex-column;
    width: 100%;
    padding: 30px 0;

    &_header {
      font-weight: bold;
      text-align: center;
      width: 100%;
      font-size: 22px;
      margin-bottom: 20px;
    }
  }

  &_choose{
    @include flex-column;
    position: relative;
    width: 100%;
  }

  &_list{
    @include flex-column;
    width: 100%;
    margin-bottom: 1rem;

    .success_now & {
      display: none;
    }

    &_items{
      @include flex-column;
      width: 100%;
    }

    &_item{
      @include align-items();
      width: 100%;
      border: 1px solid #D9D9D9;
      background: #fff;
      margin-bottom: 0.35rem;
      height: 110px;
      font-size: 1.1em;

      &_mobile {
        @include flex-column;
        width: 100%;

        &_header{
          @include padding-start(15px);
          @include padding-end(10px);
          display: flex;
          height: 80px;
          letter-spacing: .02em;
        }

        &_info {
          @include flex-column;
          flex-grow: 1;
        }

        &_checked{
          @include align-justify();
          width: 160px;
        }

        &_name{
          font-size: 28px;
          padding: 5px 0;
        }

        &_desc{
          color: #d90303;
          font-size: 28px;
          font-style: italic;
          font-weight: 700;
        }
      }
    }

    &_image {
      width: 210px;
      @include align-justify();

      &_mobile {
        width: 100%;
        @include align-justify();
      }

      & img{
        height: 100px;
        width: 170px;
      }
    }

    &_package{
      width: 25%;
      flex-grow: 1;
      @include align-justify(center,flex-start);
    }

    &_name{
      width: 30%;
      flex-grow: 1;
      @include align-justify(center,flex-start);
    }

    &_price{
      width: 11%;
      flex-grow: 1;
      @include align-justify();
    }

    &_count{
      width: 11%;
      flex-grow: 1;
      @include align-justify();
    }

    &_check{
      width: 90px;
      @include align-justify();
    }

    &_empty{
      padding: 1em 2em;
      font-size: 1.1em;
    }
  }
  &_checkout{
    margin: 1rem auto;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 500px;
    width: 100%;

    &_layer{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255,255,255,1);
      z-index: 100;
      @include align-justify();
      transition: 3.5s ease-out;

      &.disable_layer{
        left: -1900px;
        right: unset;

      }
    }

    &_success{
      @include column-align-justify();
      background-color: rgba(255,255,255,0.95);
      width: 100%;
    }

    &_page{
      @include justify(space-between);
      width: 100%;

      .success_now & {
        display: none;
      }

      .mobile & {
        flex-direction: column;
      }
    }

    & .main-data {
      position: relative;

      &.error-data{
        border-color: red;
      }

      & .disabled-data{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        backdrop-filter: blur(4px);
      }
    }

    & .disabled-block {
      & .checkbox-wrapper-12 .cbx{
        filter: blur(4px);
      }
    }

    & .checkout .accessor .heading {
      position: static;
    }

    & .checkbox-wrapper-12{
      position: static;
    }

    & .date-select .date-select-item {
      max-width: 360px;
      min-width: 360px;

      & .order-calc .total-checked-rent{
        padding: 0.5em 0.25em;
      }

      .mobile & {
        max-width: max-content;
        min-width: 100%;
        width: 100%;
        @include padding-start(0);
      }
    }
  }

  &_rules{
    @include flex-column;
    /*width: calc(100% - 400px);*/
    width: calc(100%);
    padding: 10px 20px;
    margin-bottom: 10px;
    border: 1px solid #D9D9D9;
    background: #fff;

    .mobile & {
      width: 100%;
    }

    & .header_main_color{
      font-weight: bold;
      font-size: 1.175em;
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    & .header_main{
      font-weight: bold;
      font-size: 1.175em;
    }

    & .text_main{
      font-weight: bold;
      font-size: 1.025em;
    }

    & .text_normal{
      font-size: .975em;
    }

    & .text_name{
      display: inline-block;
      flex-direction: row;
      font-size: 1em;

      &_name{
        font-weight: bold;
      }
      &_text{
        @include margin-start(5px);
        font-style: oblique;
        text-align: justify;
      }
    }

    & .table_item {
      @include flex-column;
      margin: 16px 0;

      &_name{
        font-weight: bold;
        font-size: 1.1em;
        margin-bottom: 4px;
      }

      &_note{
        font-size: 0.9em;
        font-style: oblique;
        text-align: justify;
        margin-top: 7px;
      }

      & table,& th,& td {
        border: 1px solid #bdbdbd;
        border-collapse: collapse;
      }

      & tr {
        height: 30px;
        line-height: 30px;
      }
      & th,& td {
        @include padding-start(5px);
      }
    }

    & .multitable_item{
      flex-direction: row;

      & .table_item{
        &+.table_item{
          @include margin-start(5px);
        }

        & th,& td {
          font-size: 0.9em;
        }
      }
    }

    &_item{
      @include flex-column;
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
@import "../../styles/themes/default";

.btn {
  display: inline-block;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  .mobile & {
    font-size: 2rem;
  }
}

.btn.disabled_button {
  opacity: 0.35;
  cursor: not-allowed;

  &:hover{
    color: inherit !important;
    background-color:inherit !important;
    border-color: inherit !important;
  }
}

.btn:focus,.btn:active:focus,.btn.active:focus,.btn.focus,.btn:active.focus,.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

.btn:focus,.btn:hover {
  text-decoration: none
}

.btn.focus {
  text-decoration: none
}

.btn:active,.btn.active {
  background-image: none;
  outline: 0
}

.btn.disabled,.btn:disabled {
  cursor: not-allowed;
  opacity: 0.35
}

a.btn.disabled,fieldset[disabled] a.btn {
  pointer-events: none
}

.btn-primary {
  color: #fff;
  background-color: #286aa6;
  border-color: #286aa6
}

.btn-primary:hover {
  color: #fff;
  background-color: #1e507d;
  border-color: #1c4b75
}

.btn-primary:focus,.btn-primary.focus {
  color: #fff;
  background-color: #1e507d;
  border-color: #1c4b75
}

.btn-primary:active,.btn-primary.active,.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1e507d;
  border-color: #1c4b75;
  background-image: none
}

.btn-primary:active:hover,.btn-primary.active:hover,.open > .btn-primary.dropdown-toggle:hover,.btn-primary:active:focus,.btn-primary.active:focus,.open > .btn-primary.dropdown-toggle:focus,.btn-primary:active.focus,.btn-primary.active.focus,.open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #173d60;
  border-color: #0f283f
}

.btn-primary.disabled:focus,.btn-primary:disabled:focus,.btn-primary.disabled.focus,.btn-primary:disabled.focus {
  background-color: #286aa6;
  border-color: #286aa6
}

.btn-primary.disabled:hover,.btn-primary:disabled:hover {
  background-color: #286aa6;
  border-color: #286aa6
}

.btn-secondary {
  color: #4a4a4a;
  background-color: #fff;
  border-color: #ccc
}

.btn-secondary:hover {
  color: #4a4a4a;
  background-color: #e6e6e6;
  border-color: #adadad
}

.btn-secondary:focus,.btn-secondary.focus {
  color: #4a4a4a;
  background-color: #e6e6e6;
  border-color: #adadad
}

.btn-secondary:active,.btn-secondary.active,.open > .btn-secondary.dropdown-toggle {
  color: #4a4a4a;
  background-color: #e6e6e6;
  border-color: #adadad;
  background-image: none
}

.btn-secondary:active:hover,.btn-secondary.active:hover,.open > .btn-secondary.dropdown-toggle:hover,.btn-secondary:active:focus,.btn-secondary.active:focus,.open > .btn-secondary.dropdown-toggle:focus,.btn-secondary:active.focus,.btn-secondary.active.focus,.open > .btn-secondary.dropdown-toggle.focus {
  color: #4a4a4a;
  background-color: #d4d4d4;
  border-color: #8c8c8c
}

.btn-secondary.disabled:focus,.btn-secondary:disabled:focus,.btn-secondary.disabled.focus,.btn-secondary:disabled.focus {
  background-color: #fff;
  border-color: #ccc
}

.btn-secondary.disabled:hover,.btn-secondary:disabled:hover {
  background-color: #fff;
  border-color: #ccc
}

.btn-allow {
  color: white;
  background-color: $secondary-color;
  border-color: #cccccc;
}

.btn-allow:hover {
  color: #2a2a2a;
  font-weight: bold;
  /*background-color: gold;*/
  border-color: #cccccc;
}

.btn-allow:focus,.btn-allow.focus {
  color: #2a2a2a;
  background-color: gold;
  border-color: #a48b00;
}

.btn-allow:active,.btn-allow.active,.open > .btn-allow.dropdown-toggle {
  color: #2a2a2a;
  background-color: gold;
  border-color: #a48b00;
  background-image: none
}

.btn-allow:active:hover,.btn-allow.active:hover,.open > .btn-allow.dropdown-toggle:hover,.btn-allow:active:focus,.btn-allow.active:focus,.open > .btn-allow.dropdown-toggle:focus,.btn-allow:active.focus,.btn-allow.active.focus,.open > .btn-allow.dropdown-toggle.focus {
  color:#2a2a2a;
  background-color: #ceac06;
  border-color: #a48b00;
}

.btn-allow.disabled:focus,.btn-allow:disabled:focus,.btn-allow.disabled.focus,.btn-allow:disabled.focus {
  background-color: #fff;
  border-color: #ccc
}

.btn-allow.disabled:hover,.btn-allow:disabled:hover {
  background-color: #fff;
  border-color: #ccc
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2
}

.btn-info:focus,.btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2
}

.btn-info:active,.btn-info.active,.open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
  background-image: none
}

.btn-info:active:hover,.btn-info.active:hover,.open > .btn-info.dropdown-toggle:hover,.btn-info:active:focus,.btn-info.active:focus,.open > .btn-info.dropdown-toggle:focus,.btn-info:active.focus,.btn-info.active.focus,.open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1f7e9a
}

.btn-info.disabled:focus,.btn-info:disabled:focus,.btn-info.disabled.focus,.btn-info:disabled.focus {
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-info.disabled:hover,.btn-info:disabled:hover {
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #419641
}

.btn-success:focus,.btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #419641
}

.btn-success:active,.btn-success.active,.open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
  background-image: none
}

.btn-success:active:hover,.btn-success.active:hover,.open > .btn-success.dropdown-toggle:hover,.btn-success:active:focus,.btn-success.active:focus,.open > .btn-success.dropdown-toggle:focus,.btn-success:active.focus,.btn-success.active.focus,.open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #2d672d
}

.btn-success.disabled:focus,.btn-success:disabled:focus,.btn-success.disabled.focus,.btn-success:disabled.focus {
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-success.disabled:hover,.btn-success:disabled:hover {
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316
}

.btn-warning:focus,.btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316
}

.btn-warning:active,.btn-warning.active,.open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
  background-image: none
}

.btn-warning:active:hover,.btn-warning.active:hover,.open > .btn-warning.dropdown-toggle:hover,.btn-warning:active:focus,.btn-warning.active:focus,.open > .btn-warning.dropdown-toggle:focus,.btn-warning:active.focus,.btn-warning.active.focus,.open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #b06d0f
}

.btn-warning.disabled:focus,.btn-warning:disabled:focus,.btn-warning.disabled.focus,.btn-warning:disabled.focus {
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-warning.disabled:hover,.btn-warning:disabled:hover {
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-danger {
  color: #fff !important;
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a
}

.btn-danger:focus,.btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a
}

.btn-danger:active,.btn-danger.active,.open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
  background-image: none
}

.btn-danger:active:hover,.btn-danger.active:hover,.open > .btn-danger.dropdown-toggle:hover,.btn-danger:active:focus,.btn-danger.active:focus,.open > .btn-danger.dropdown-toggle:focus,.btn-danger:active.focus,.btn-danger.active.focus,.open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #8b211e
}

.btn-danger.disabled:focus,.btn-danger:disabled:focus,.btn-danger.disabled.focus,.btn-danger:disabled.focus {
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-danger.disabled:hover,.btn-danger:disabled:hover {
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-outline-primary {
  color: #286aa6;
  background-image: none;
  background-color: transparent;
  border-color: #286aa6
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #286aa6;
  border-color: #286aa6
}

.btn-outline-primary:focus,.btn-outline-primary.focus {
  color: #fff;
  background-color: #286aa6;
  border-color: #286aa6
}

.btn-outline-primary:active,.btn-outline-primary.active,.open > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #286aa6;
  border-color: #286aa6
}

.btn-outline-primary:active:hover,.btn-outline-primary.active:hover,.open > .btn-outline-primary.dropdown-toggle:hover,.btn-outline-primary:active:focus,.btn-outline-primary.active:focus,.open > .btn-outline-primary.dropdown-toggle:focus,.btn-outline-primary:active.focus,.btn-outline-primary.active.focus,.open > .btn-outline-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #173d60;
  border-color: #0f283f
}

.btn-outline-primary.disabled:focus,.btn-outline-primary:disabled:focus,.btn-outline-primary.disabled.focus,.btn-outline-primary:disabled.focus {
  border-color: #5c9dd8
}

.btn-outline-primary.disabled:hover,.btn-outline-primary:disabled:hover {
  border-color: #5c9dd8
}

.btn-outline-secondary {
  color: #ccc;
  background-image: none;
  background-color: transparent;
  border-color: #ccc
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc
}

.btn-outline-secondary:focus,.btn-outline-secondary.focus {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc
}

.btn-outline-secondary:active,.btn-outline-secondary.active,.open > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc
}

.btn-outline-secondary:active:hover,.btn-outline-secondary.active:hover,.open > .btn-outline-secondary.dropdown-toggle:hover,.btn-outline-secondary:active:focus,.btn-outline-secondary.active:focus,.open > .btn-outline-secondary.dropdown-toggle:focus,.btn-outline-secondary:active.focus,.btn-outline-secondary.active.focus,.open > .btn-outline-secondary.dropdown-toggle.focus {
  color: #fff;
  background-color: #a1a1a1;
  border-color: #8c8c8c
}

.btn-outline-secondary.disabled:focus,.btn-outline-secondary:disabled:focus,.btn-outline-secondary.disabled.focus,.btn-outline-secondary:disabled.focus {
  border-color: #fff
}

.btn-outline-secondary.disabled:hover,.btn-outline-secondary:disabled:hover {
  border-color: #fff
}

.btn-outline-info {
  color: #5bc0de;
  background-image: none;
  background-color: transparent;
  border-color: #5bc0de
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-outline-info:focus,.btn-outline-info.focus {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-outline-info:active,.btn-outline-info.active,.open > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-outline-info:active:hover,.btn-outline-info.active:hover,.open > .btn-outline-info.dropdown-toggle:hover,.btn-outline-info:active:focus,.btn-outline-info.active:focus,.open > .btn-outline-info.dropdown-toggle:focus,.btn-outline-info:active.focus,.btn-outline-info.active.focus,.open > .btn-outline-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1f7e9a
}

.btn-outline-info.disabled:focus,.btn-outline-info:disabled:focus,.btn-outline-info.disabled.focus,.btn-outline-info:disabled.focus {
  border-color: #b0e1ef
}

.btn-outline-info.disabled:hover,.btn-outline-info:disabled:hover {
  border-color: #b0e1ef
}

.btn-outline-success {
  color: #5cb85c;
  background-image: none;
  background-color: transparent;
  border-color: #5cb85c
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-outline-success:focus,.btn-outline-success.focus {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-outline-success:active,.btn-outline-success.active,.open > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-outline-success:active:hover,.btn-outline-success.active:hover,.open > .btn-outline-success.dropdown-toggle:hover,.btn-outline-success:active:focus,.btn-outline-success.active:focus,.open > .btn-outline-success.dropdown-toggle:focus,.btn-outline-success:active.focus,.btn-outline-success.active.focus,.open > .btn-outline-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #2d672d
}

.btn-outline-success.disabled:focus,.btn-outline-success:disabled:focus,.btn-outline-success.disabled.focus,.btn-outline-success:disabled.focus {
  border-color: #a3d7a3
}

.btn-outline-success.disabled:hover,.btn-outline-success:disabled:hover {
  border-color: #a3d7a3
}

.btn-outline-warning {
  color: #f0ad4e;
  background-image: none;
  background-color: transparent;
  border-color: #f0ad4e
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-outline-warning:focus,.btn-outline-warning.focus {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-outline-warning:active,.btn-outline-warning.active,.open > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-outline-warning:active:hover,.btn-outline-warning.active:hover,.open > .btn-outline-warning.dropdown-toggle:hover,.btn-outline-warning:active:focus,.btn-outline-warning.active:focus,.open > .btn-outline-warning.dropdown-toggle:focus,.btn-outline-warning:active.focus,.btn-outline-warning.active.focus,.open > .btn-outline-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #b06d0f
}

.btn-outline-warning.disabled:focus,.btn-outline-warning:disabled:focus,.btn-outline-warning.disabled.focus,.btn-outline-warning:disabled.focus {
  border-color: #f8d9ac
}

.btn-outline-warning.disabled:hover,.btn-outline-warning:disabled:hover {
  border-color: #f8d9ac
}

.btn-outline-danger {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border-color: #d9534f
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-outline-danger:focus,.btn-outline-danger.focus {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-outline-danger:active,.btn-outline-danger.active,.open > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-outline-danger:active:hover,.btn-outline-danger.active:hover,.open > .btn-outline-danger.dropdown-toggle:hover,.btn-outline-danger:active:focus,.btn-outline-danger.active:focus,.open > .btn-outline-danger.dropdown-toggle:focus,.btn-outline-danger:active.focus,.btn-outline-danger.active.focus,.open > .btn-outline-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #8b211e
}

.btn-outline-danger.disabled:focus,.btn-outline-danger:disabled:focus,.btn-outline-danger.disabled.focus,.btn-outline-danger:disabled.focus {
  border-color: #eba5a3
}

.btn-outline-danger.disabled:hover,.btn-outline-danger:disabled:hover {
  border-color: #eba5a3
}

.btn-link {
  font-weight: normal;
  color: #286aa6;
  border-radius: 0

}

.btn-link,.btn-link:active,.btn-link.active,.btn-link:disabled {
  background-color: transparent
}

.btn-link,.btn-link:focus,.btn-link:active {
  border-color: transparent
}

.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus,.btn-link:hover {
  color: #ff7878;
/*  text-decoration: underline;*/
  background-color: transparent
}

.btn-link:disabled:focus,.btn-link:disabled:hover {
  color: #d4e1ed;
  text-decoration: none
}

.btn-lg,.btn-group-lg > .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem
}

.btn-sm,.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem
}

.btn-block {
;display: block;
  width: 100%
}

.btn-block + .btn-block {
  margin-top: 0.5rem
}

 .btn_more,.btn-more {
  padding: 1rem 2rem;
  font-weight: 500;
  letter-spacing: 0.075rem;
  clip-path: polygon(10px 0%, 100% 0%, calc(100% - 10px) 100%, 0% 100%);
  background: #d83737;
  display: block;
  width: auto;
  color: white;
  border: none;
  text-align: center;
  text-transform: uppercase;
  -webkit-text-decoration: auto;
  text-decoration: auto;
  cursor: pointer;
  position: relative;
  html[lang=he] & {
    font-size: 18px;
  }
  &:visited {
    color: white;
  }
  &::before {
    content: '';
    background: white;
    display: none;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    clip-path: polygon(10px 0%, 100% 0%, calc(100% - 10px) 100%, 0% 100%);
    z-index: -1;
  }
  &.active, &:hover {
    background: #c12e2a;
    color: black;
    &::before {
      display: block;
    }
  }
  .mobile & {
    font-size: 2rem;
  }

   &.secondary_btn {
     color: #d83737;
     background: white;

     &:hover {
       background: #c12e2a;
       color: black;
     }
   }
}


@import "../../../styles/mixin";
@import "../../../styles/themes/default";

.checkbox-wrapper-12 {
  &.chk-input{
    .input-item{
      height: 1.7em;
      width: 4em;
      margin: unset;
   /*   border: 1px solid #ddd;*/
    }
  }

  position: relative;
  @include align-items();
  height: 1.7em;
  font-size: 1.12em;
  line-height: 1em;
  padding: 0.5rem 0.2rem;
  cursor: pointer;

  .label {
    padding-left: 15px;
    .rental_values & {
      width: 50%;
    }
    html[lang=he] & {
      padding-left: 0;
      padding-right: 15px;
    }
    font-weight: 500;
    @include margin-end(1rem);
  }

  .mobile & {
    height: auto;
  }

  .count{
    flex-grow: 1;
    @include align-justify(center,flex-end);
    display: none;

    &.count_active{
      display: flex;

      & select{
        min-width: 60px;
        padding: 0.2rem 0.65rem
      }
    }
  }

  &:hover{
    background-color: #f9f9f9;
  }
}

.checkbox-wrapper-12 > svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.checkbox-wrapper-12 * {
  box-sizing: border-box;
}

.checkbox-wrapper-12 input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.checkbox-wrapper-12 input[type="checkbox"]:focus {
  outline: 0;
}

.checkbox-wrapper-12 .cbx {
  width: 18px;
  height: 18px;
  position: relative;
}

.checkbox-wrapper-12 .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #bfbfc0;

  .radio_values & {
    border-radius: 50%;
  }
}

.checkbox-wrapper-12 .cbx label {
  width: 18px;
  height: 18px;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-filter: url("#goo-12");
  filter: url("#goo-12");
  transform: translate3d(0, 0, 0);
  pointer-events: none;

  .radio_values & {
    border-radius: 50%;
  }
}

.checkbox-wrapper-12 .cbx svg {
  position: absolute;
  top: 2px;
  left: 1px;
  z-index: 1;
  pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg path {
  stroke: white;
  stroke-width: 2;
  /*stroke-linecap: round;
  stroke-linejoin: round;*/
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  transition: stroke-dashoffset 0.3s ease;
/*  transition-delay: 0.2s;*/
}

.checkbox-wrapper-12 .cbx input:checked + label {
  animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper-12 .cbx input:checked + label + svg path {
  stroke-dashoffset: 0;
}

@-moz-keyframes splash-12 {
  40% {
    background: $secondary-color;
    box-shadow: 0 -18px 0 -8px $secondary-color, 16px -8px 0 -8px $secondary-color, 16px 8px 0 -8px $secondary-color, 0 18px 0 -8px $secondary-color, -16px 8px 0 -8px $secondary-color, -16px -8px 0 -8px $secondary-color;
  }
  100% {
    background: $secondary-color;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-webkit-keyframes splash-12 {
  40% {
    background: $secondary-color;
    box-shadow: 0 -18px 0 -8px $secondary-color, 16px -8px 0 -8px $secondary-color, 16px 8px 0 -8px $secondary-color, 0 18px 0 -8px $secondary-color, -16px 8px 0 -8px $secondary-color, -16px -8px 0 -8px $secondary-color;
  }
  100% {
    background: $secondary-color;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-o-keyframes splash-12 {
  40% {
    background: $secondary-color;
    box-shadow: 0 -18px 0 -8px $secondary-color, 16px -8px 0 -8px $secondary-color, 16px 8px 0 -8px $secondary-color, 0 18px 0 -8px $secondary-color, -16px 8px 0 -8px $secondary-color, -16px -8px 0 -8px $secondary-color;
  }
  100% {
    background: $secondary-color;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: $secondary-color;
    box-shadow: 0 -18px 0 -8px $secondary-color, 16px -8px 0 -8px $secondary-color, 16px 8px 0 -8px $secondary-color, 0 18px 0 -8px $secondary-color, -16px 8px 0 -8px $secondary-color, -16px -8px 0 -8px $secondary-color;
  }
  100% {
    background: $secondary-color;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}


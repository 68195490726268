@import "../../styles/themes/default.scss";

input[type="range"] {
  -webkit-appearance: none;
  height: 7px;
  background: grey;
  border-radius: 5px;
  background-image: linear-gradient($secondary-color, $secondary-color);
  background-repeat: no-repeat;
  border: 1px solid #bbb;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: gold;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #111;
  transition: background 0.3s ease-in-out;

}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: #00000060 0px 0px 0px 2px;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: #00000060 0px 0px 0px 4px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

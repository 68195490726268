@import "../styles/mixin";
@import "../styles/themes/default";

.routes {
  background: url(/www/images/bikes/back-moto.jpg) no-repeat bottom;
  padding-bottom: 30px;
  &_wrap {
    background: white;
  }
}
.map_container {
  @include align-justify();
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  padding: 30px 0;
  & .map {
    @include flex-column();
    width: calc(50% - 20px);
    .mobile & {
      width: calc(100% - 20px);
    }
    & h2 {
      font-size: 22px;
      text-transform: none;
    }
    & iframe {
      width: 100%;
      height: 400px;
      padding: 10px 0;
    }
    & .btn_more {
      margin: 0 auto;
      text-transform: uppercase;
    }
  }
}
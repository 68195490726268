@import "../../variables";
@import "../../styles/mixin";

.footer {
  height: 290px;
  background: #0B0D0F;
  color: white;
  .mobile & {
    min-height: 600px;
    height: max-content;
    font-size: 1.6em;
  }

  &_wrap {
      display: flex;
      flex-direction: column;
      position: relative;
  }

  &_content {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #333;

    .mobile & {
      flex-direction: column;
      font-size: 0.9em;
    }

    &_area{
      @include d-flex;
      width: 72%;

      .mobile & {
        width: 100%;
      }
    }

    &_item {
      padding-top: 20px;
      width: 28%;
      text-indent: 30px;
      .mobile & {
        width: 100%;
        text-align: center;
        text-indent: unset;
      }

      & .logo {
        display: block;
        width: 160px;
        height: 135px;
        background: url("/www/images/logo.png") no-repeat;
        background-size: cover;
        /*background: url("/www/images/tamamoto-logo.png") no-repeat;
        background-size: contain;*/
        margin: 0 30px;
        .mobile & {
          margin: 0 auto;
        }
      }
      & .heading {
        padding-inline-start: 40px;
        font-size: 1.2em;
        font-weight: 600;
        .mobile & {
          padding-inline-start: unset;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding-top: 20px;

        li {
          min-height: 28px;

          .mobile & {
            min-height: 60px;
            @include align-justify();
            width: 100%;
          }

          margin: 2px 0;
          font-size: 1em;

          span.icon {
            margin: 0 5px;
          }

          a {
            text-decoration: none;
            color: white;
            position: relative;
            unicode-bidi: plaintext;
            &::after {
              content: "";
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #d90303;
              transition: opacity 300ms, transform 300ms;
              transform: scale(0);
              transform-origin: center;
            }
            &:hover {
              color: #d90303;
              &::after {
                transform: scale(1);
              }
            }
          }
          .icon_phone, .icon_email {
            width: 30px;
            height: 18px;
            background-size: contain;
            display: inline-block;
            vertical-align: baseline;

            .mobile & {
              width: 42px;
              height: 25px;
            }
          }
          .icon_phone {
            background: url("/www/images/icons/phone.svg") no-repeat;
          }
          .icon_email {
            background: url("/www/images/icons/email.svg") no-repeat;
          }
        }
      }

      .footer_content_area & {
        width: calc(100% / 3);
      }
    }
    &_bottom_top{
      position: absolute;
      right: 10px;
      bottom: 20px;
      html[lang=he] & {
        left: 10px;
        right: auto;
      }

      &_button{
        text-align: center;
        background-color: #1f1f1f;
        border: 1px solid #333;
        border-radius: 2px;
        height: 30px;
        line-height: 35px;
        width: 30px;
        cursor: pointer;

        &:hover{
          background-color: #282828;
        }

        svg{
          color: #65666C;
          font-size: 1.35rem;
        }
      }
    }
  }
  &_sub {
    height: 40px;
    font-size: 1em;
    display: flex;
    justify-content: center;
/*    line-height: 36px;*/

    .mobile & {
      font-size: 0.9em;
      height: 60px;
    }

    &_links {
   /*   width: calc(100% / 3);*/
      @include align-justify(center,flex-start);
      @include padding-start(40px);

      &_item{
        height: 28px;
        @include margin-end(10px);

        .mobile & {
          height: 42px;
          @include margin-end(15px);
        }

        & img{
          height: 100%;
        }
      }
    }

    &_site{
      @include align-justify();
      flex-grow: 1;
    }
  }
}


/*.footer {
  &-wrap {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
    @include d-flex;

   .mobile & {
      max-width: 100%;
    }

    & .heading {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      letter-spacing: -1px;
      margin-bottom: 1.5rem;
      font-variant-caps: petite-caps;
      text-decoration: underline;
      text-underline-position: under;

      .mobile & {
        font-size: 1.5rem;
      }
    }
  }

  &-content {
    @include flex-column;
    width: 100%;
    border-bottom: 1px solid #333;
    height: calc($footer_height - 50px);


    &-bottom{
      @include align-items();
      width: 100%;
      height: 50px;


      &-text{
        flex-grow: 1;
        color:#ccc;
        font-size: 0.95rem;
        text-align: center;

        .mobile & {
          font-size: 1.2rem;
        }
      }

      &-top{
        position: absolute;
        right: 10px;
        bottom: 20px;
        html[lang=he] & {
          left: 10px;
          right: auto;
        }

        &-button{
          text-align: center;
          background-color: #1f1f1f;
          border: 1px solid #333;
          border-radius: 2px;
          height: 30px;
          line-height: 35px;
          width: 30px;
          cursor: pointer;

          &:hover{
            background-color: #282828;
          }

          & svg{
            color: #65666C;
            font-size: 1.35rem;
          }
        }
      }
    }
  }

  &-block {
    width: 33%;
    padding-top: 20px;
    color: white;
    text-indent: 30px;
  }

  &-text{
    color: #ccc;
    font-size: 0.95em;
    @include column-align-items(flex-start);

    .mobile & {
      font-size: 1.2rem;
    }

    & span{
      margin-bottom: 0.5em;

      .mobile & {
        margin-bottom: 0.3em;
      }
    }
  }

  &-links{
    margin: 0.6rem 0;
    @include flex-column;

    .mobile & {
      font-size: 1.2rem;
    }

    & a {
      color: #ffae00;
      margin-bottom: 0.3rem;


      &:hover{
        text-decoration: underline;
      }

      &:visited{
        color: #ffae00;
      }
    }
  }
}/**/
#data-range {
  width: 500px;
  height: 20px;
  background-color: red;
  position: relative;
  .slide{
    width: 30px;
    height: 30px;
    background-color: blue;
    position: absolute;
    top:-5px;
  }
}
@import "../styles/mixin";
@import "../styles/themes/default";

.gallery {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: url("/www/images/bikes/back-moto.jpg") no-repeat bottom;
  padding-bottom: 30px;

  &_wrap {
    @include flex-column();
    /*    max-width: 1200px;
       min-width: 1200px;
    max-width: 95%;
    min-width: 95%;
    margin: 0 auto;*/

    &_header {
      @include column-align-items();
      background: $secondary-color;
      border: 1px solid #555;
      @include padding-start(10px);
      @include padding-end(10px);
      width: 100%;
      box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
      margin-bottom: 1rem;

      h2 {
        @include column-align-items();
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;

        .mobile & {

          font-size: 2.2rem;
        }
      }
    }
  }

  &_area{
    width: 100%;
    @include flex-column();
  }

  &_albums{
    width: 100%;
    @include flex-wrap();
    /*   background: #fff;*/
    padding: 2rem 2rem 0;
    margin-bottom: 1rem;
    justify-content: center;

    &_item {
      width: calc((100% - 2 * 2%) / 3);
      @include margin-end(2%);
      margin-bottom: 2%;
      /*box-shadow: 5px 5px 5px rgb(0 0 0 / 50%);*/
      border: 1px solid #787878;
      height: 480px;

      &_info > div {
        background-repeat: no-repeat;
        background-position: left center;
        html[lang=he] & {
          background-position: right center;
        }
      }

      h3 {
        font-size: 1em;
        font-weight: 400;
        .mobile & {
          font-size: 1.8em;
        }
      }

      &_button {
        position: absolute;
        bottom: 10px;
        right: 5px;
        html[lang=he] & {
          right: auto;
          left: 5px;
        }
      }

      &:nth-child(3n) {
        @include margin-end(0);
      }

      .mobile & {
        width: 100% ;
        @include margin-end(0);
      }

      &_picture {
        width: 100%;
        display: block;
        height: 100%;
        &_gradient {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%,rgba(0, 0, 0, 0.8) 100%);
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: end;
          flex-direction: column;
          & > {
            width: 100%;
            position: relative;
            height: 100%;
          }
        }


        &:hover {
          & .gallery_albums_title {
            /*          background: rgba(208, 119, 4, 0.75);*/
            /*  background: rgba(229, 199, 22, 0.8);*/
            min-width: 100%;
            bottom: 0;
            filter: brightness(1.1);
          }

          & .gallery_albums-pict {
            filter: contrast(1.15);
          }
        }
      }


      &_footer {
        @include flex-column;
        width: 100%;
        padding: 1%;
        background: #222;
        color: #f5f5f5;

        .mobile & {
          font-size: 1.75rem;
        }
      }

      .gallery_albums-pict {

        width: 100%;
        height: 100%;
        cursor: pointer;
        position: relative;
        display: block;
        text-decoration: auto;


        &.empty-pict {
          background-color: #8f8f8f;
        }
      }

      &_title {
        width: 100%;
        padding: 1rem;
        color: #000;

        & h2 {
          font-weight: 600;
          font-size: 24px;
          text-transform: uppercase;
          color: white;


          .mobile & {
            font-size: 2.5rem;
          }
        }

        .mobile & {
          bottom: 0rem;
        }
      }
      &_body {
        height: 117px;
        width: 100%;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px;

      }
      &_info {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        & > div {
          min-height: 20px;
          padding-inline-start: 30px;
        }
      }

      & label {
        font-weight: 600;
        @include margin-end(5px);
      }

      & .btn_more {
        width: auto;
        margin-inline-end: 10px;
        .mobile & {
          font-size: 1.2em;
        }
      }

      &_more {
        color: white;
        cursor: pointer;
        padding: 1rem 2rem;
        font-weight: 500;
        letter-spacing: 0.075rem;
        clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        border: 1px solid silver;
        display: block;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}
@import "../../../styles/mixin";
@import "../../../styles/themes/default";

.DatePicker{
 /* margin-top: 20px;*/
  position: relative;
  display: inline-block;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
  max-width: 350px;
  min-width: 350px;
  background: white;

  .piker-layer{
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(190, 190, 190, 0.92);
    z-index: 500;
    .btn-panel {
      display: flex;
      height: 100%;
      justify-content: space-evenly;
      width: max-content;
      flex-direction: column;
      margin: 0 auto;
    }
  }
  .mobile & {
    max-width: 100%;
    width: 100%;
  }

  & .btn{
    white-space: unset;
    padding: 0.3rem 0.5rem;
    font-size: .95rem;

    .mobile & {
      font-size: 2rem;
      padding: 0.75rem 1.5rem;
    }

  }
}

.CalendarPanel{
  @include flex-column;
  user-select: none;

  &__content {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    grid-template-columns: repeat(7, 1fr);
    border-top: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
  }

}

.CalendarPanelItem {
  height: 50px;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 4px;
  @include align-justify(flex-start,flex-end);
  cursor: pointer;
  @include transition(0.5s);
  background-color: #fff;

  .mobile & {
    font-size: 2rem;
    height: 80px;
    justify-content: center;
    align-items: center;
  }

  &.dayWeekItem{
    background: #eee;
    @include align-justify();

    .mobile & {
      font-size: 2rem;
    }
  }
  &--available {
    background: #05c9f82b;
  }
  &--in{
    background: $secondary-color;
    font-weight: 500;
    color: white;
  }
  &--used{
     background: #f38989;
    font-weight: 500;
    cursor: not-allowed;
    opacity: 0.7;
    color: #777777;
  }
  &--used-me{
    background: #f5fd27;
    font-weight: 500;
    cursor: not-allowed;
    opacity: 0.7;
    color: #777777;
  }

}

.DatePicker__input {
  border: 1px solid lightgrey;
  padding: 6px 12px;
  border-radius: 6px;
}

.DatePicker__input:focus {
  outline: none;
}

.DatePicker__input--invalid {
  border-color: red;
  color: red;
}

.DatePicker__popup {
  position: absolute;
  top: 100%;
  left: 0;
}

.CalendarPanel__header {
 /* border: 1px solid lightgrey;
  border-bottom: none;
  background: #fcfcfc;*/
  /*border: 1px solid #555;
  background: $secondary-color;*/
  border: 1px solid #ccc;
}

.CalendarPanel__date {
  margin-top: 8px;
  text-align: center;
  font-weight: 700;

  .mobile & {
    font-size: 1.75rem;
  }
}



.CalendarPanel__buttons {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.CalendarPanel__buttons-right,
.CalendarPanel__buttons-left {
  display: flex;
  gap: 8px;
  margin: 0 8px;
}



.CalendarPanelItem__date {
  border-radius: 50%;
  padding: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .mobile & {
    width: 50px;
    height: 50px;
  }
}

.CalendarPanelItem--selected .CalendarPanelItem__date {
  background-color: red;
  color: white;
}

.CalendarPanelItem--not-current .CalendarPanelItem__date {
  opacity: 0.5;
}

.CalendarPanelItem--not-in-range {
  background: #f38989;
  color: #777777;
  cursor: not-allowed;
  opacity: 0.7;
}

.CalendarPanelItem--not-in-range .CalendarPanelItem__date {
  opacity: 0.5;
}

.CalendarPanelItem--today {
  color: red;
  opacity: 1;
}
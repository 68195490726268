@import '../../styles/mixin';
@import '../../styles/themes/default';

.my_packages {
  @include flex-column;
  width: 100%;

  &_list {
    margin-bottom: 16px;
    list-style: none;

    &_empty {
      font-size: 18px;
      font-weight: bold;
      color: #817e7e;

      .mobile & {
        font-size: 1.5rem;
      }
    }

    &_item {
      padding: 16px;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      margin-bottom: 5px;

      &_heading {
        position: relative;
        @include padding-start(24px);
        user-select: none;
        cursor: pointer;

        &_decor {
          position: absolute;
          @include start(0);
          top: 0;
          display: block;
          height: 100%;
          width: 8px;
          border-radius: 50px;
          transition: all .2s ease-in-out;
          background-color: #ddd;

          &.paid_item {
            background-color: #9cb4e8;
          }

          &.performed_item {
            background-color: #9ce8dd;
          }

          &.completed_item {
            background-color: #4dd75f;
          }

          &.canceled_item {
            background-color: #e07575;
          }

          &:before {
            position: absolute;
            @include start(0);
            top: 0;
            display: block;
            height: 100%;
            width: 8px;
            border-radius: 50px;
            transition: all .2s ease-in-out;
            content: "";
          }
        }

        &_row {
          @include align-items();
          min-height: 40px;
          @include padding-end(24px);
          width: 100%;

          .mobile & {
            flex-direction: column;
          }
        }

        &_status {
          width: 40%;
          margin-bottom: 0;
          @include column-align-justify(flex-start, space-between);
          min-height: 32px;

          .mobile & {
            width: 100%;
            margin-bottom: 6px;
          }
        }

        &_label {
          font-size: 12px;
          color: #797878;
          direction: ltr;

          & time {
            @include margin-start(5px);
          }

          .mobile & {
            font-size: 1.75rem;
            margin-bottom: 6px;
          }
        }

        &_value {
          font-size: 14px;

          .mobile & {
            font-size: 2.15rem;
            margin-bottom: 6px;
          }
        }

        &_price {
          width: 20%;
          margin-bottom: 0;
          min-height: 32px;
          @include column-align-justify(flex-start, space-between);


          .mobile & {
            width: 100%;
            margin-bottom: 6px;
          }
        }

        &_goods {
          @include align-justify(center, flex-end);
          width: 40%;
          @include margin-end(24px);

          &_item {
            @include margin-end(5px);

            .mobile & {
              height: 60px;
              @include margin-end(10px);

              & img {
                height: 100%;
              }
            }
          }

          .mobile & {
            width: calc(100% - 24px);
            @include align-justify(center, flex-start);
          }
        }

        &_chevron {
          position: absolute;
          @include end(0);
          top: 8px;
          width: 24px;
          height: 24px;

          .mobile & {
            height: 3rem;
            width: 3rem;
          }

          & svg {
            fill: #3e77aa;
            height: 24px;
            width: 24px;

            .mobile & {
              height: 3rem;
              width: 3rem;
            }
          }
        }
      }

      &_details {
        @include d-flex;
        padding-top: 16px;
        @include padding-start(24px);

        .mobile & {
          flex-direction: column;
        }

        &_summary {
          width: 40%;
          @include padding-end(24px);
          @include column-align-items(flex-start);

          .mobile & {
            width: 100%;
            @include padding-end(0);
            margin-bottom: 16px;
          }

          & section{
            font-size: 14px;
            margin-bottom: 15px;

            .mobile & {
              font-size: 1.6rem;
              margin-bottom: 10px;
            }
          }

          &_links{
            flex-grow: 1;
            @include column-align-justify(flex-start,flex-end);
          }

          &_item{
            padding-bottom: 5px;

            html[lang=he] & {
              unicode-bidi: plaintext;
            }

            &.link_item{
              margin-bottom: 0.5rem;
            }

            &.main_item{
              font-weight: 600;
            }
          }
        }

        &_goods{
          width: 60%;

          .mobile & {
            width: 100%;
          }

          &_items{
            @include flex-column;
            width: 100%;
            margin-bottom: 24px;
          }

          &_tile{
            @include align-items(flex-start);

            &_title{
              width: 80%;
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 0;

              .mobile & {
                font-size: 1.5rem;
                line-height: 1.75rem;
                width: 80%;
              }
            }

            &_figure{
              @include align-items();

              & figcaption{
                display: block;
              }
            }

            &_picture{
              position: relative;
              @include align-justify();
              flex-shrink: 0;
              width: 90px;
              height: 60px;
              @include margin-end(16px);

              .mobile & {
                width: 120px;
                height: 80px;
              }

              &.without_image{
                height: 30px;
              }

              & img{
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;
              }
            }

            &_options{
              width: 20%;
              @include align-items();

              .mobile & {
                width: 20%;
                @include justify(flex-end);
              }
            }

            &_option{
              flex-grow: 1;
              text-align: center;
              padding-left: 8px;
              padding-right: 8px;
            }

            &_label{
              display: block;
              margin-bottom: 4px;
              color: #797878;
              font-size: 12px;

              .mobile & {
                font-size: 1.375rem;
                margin-bottom: 8px;
              }
            }

            &_digit{
              font-size: 14px;

              .mobile & {
                font-size: 1.5rem;
              }
            }
          }
        }

        &_payments{
          list-style: none;

          &_row{
            @include align-justify(center,space-between);
            font-size: 14px;
            margin-bottom: 12px;

            .mobile & {
              font-size: 1.6rem;
            }
          }

          &_label{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            font-size: 14px;
            color: #797878;

            .mobile & {
              font-size: 1.5rem;
            }
          }

          &_value{
            &.sum_value{
              font-size: 18px;
              font-weight: bold;

              .mobile & {
                font-size: 1.75rem;
              }
            }
          }
        }

        &_label {
          @include align-items();
          margin-bottom: 16px;
          font-size: 14px;
          color: #797878;

          .mobile & {
            font-size: 1.5rem;
          }
        }
      }
    }

    &_children{
      @include flex-column;
      width: 100%;

      &_header{
        height: 25px;
        background-color: #e7e7e7;
        @include align-items;
        @include padding-start(20px);
        font-size: 14px;

         .mobile & {
           height: 40px;
           font-size: 18px;
         }
      }
    }
  }

  &_history{
    @include flex-column;
    width: 100%;
    padding: 24px;

    &_items{
      list-style: none;
    }

    &_item{
      position: relative;
      @include align-items();
      @include padding-start(32px);
      padding-top: 8px;
      padding-bottom: 8px;
      color: #100f0f;

      &:first-child .orders_history_item_status {
        font-size: 14px;
        color: #100f0f;

        .mobile & {
          font-size: 2rem;
        }
      }

      &:first-child .orders_history_item_decor {
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
      }

      &:last-child .orders_history_item_decor {
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      &_decor{
        position: absolute;
        @include start(0);
        top: 0;
        display: block;
        height: 100%;
        width: 8px;
        transition: all .2s ease-in-out;
        background-color: #ddd;

        &.drop_off {
          background-color: #4dd75f;
        }

        &.cancel {
          background-color: #e07575;
        }
      }

      &_date{
        width: 100px;
        @include margin-end(48px);
        margin-bottom: 0;
        font-size: 12px;

        .mobile & {
          font-size: 1.4rem;
          width: 140px;
        }
      }

      &_status{
        font-size: 14px;

        .mobile & {
          font-size: 1.25rem;
        }
      }
    }
  }
}
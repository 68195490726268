@import "../styles/mixin";
@import "../styles/themes/default";

.tours {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: url("/www/images/bikes/back-moto.jpg") no-repeat bottom;
  padding-bottom: 30px;

  &_wrap {
    @include flex-column();
    /*    max-width: 1200px;
       min-width: 1200px;
    max-width: 95%;
    min-width: 95%;
    margin: 0 auto;*/

    &_header {
      @include column-align-items();
      background: $secondary-color;
      border: 1px solid #555;
      @include padding-start(10px);
      @include padding-end(10px);
      width: 100%;
      box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
      margin-bottom: 1rem;

      h2 {
        @include column-align-items();
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;

        .mobile & {

          font-size: 2.2rem;
        }
      }
    }
  }

  .promo {
    @include flex-column();
    background: #fff;
    width: calc(100% - 4rem);
    box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
/*    clip-path: polygon(20px 0%, 100% 0%, calc(100% - 20px) 100%, 0% 100%);*/
    padding: 10px;
    border: 1px solid #D90303;
    padding-inline-start: 25px;
    font-size: 0.867em;
    @include margin-end(2rem);
    @include margin-start(2rem);

    h1 {
      text-align: center;
      font-weight: 500;
    }

    &_subtitle {
      font-size: 1.1em;
      margin-bottom: 3px;
      font-weight: 500;
    }

    &_notes {
      margin-top: 4px;
      font-size: .87em;
      font-style: italic;
      color: grey;
    }
  }

  &_days {
    @include align-justify(center, space-evenly);
    @include flex-wrap();
    background: #222;
    height: 100px;
    margin-top: 1rem;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
    /*    margin-bottom: 1rem;*/

    .mobile & {
      height: 200px;
    }

    & .day-item {
      min-width: calc(100% / 6);
      max-width: calc(100% / 4);
      @include align-items();
      height: 100%;
    }

    & .day-button {
      margin: 20%;
      /*border: 1px solid black;/**/
      height: 60%;
      border-radius: 50%;
      text-align: center;
      width: 60%;
      @include column-align-justify();
      font-size: 1.5em;
      font-weight: 500;
      cursor: pointer;
      transition: 0.5s;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      &:hover, &.active-button {
        background-color: #777;
        color: $secondary-color;
        box-shadow: rgba(0, 0, 0, 0.5) 10px 10px 20px;
      }
    }

    & .MuiSlider-root {
      width: 60%;
      color: gold !important;
    }

    & .MuiSlider-mark {
      position: absolute;
      width: 10px !important;
      height: 10px !important;
      border-radius: 7px !important;
      background-color: #fff !important;
      cursor: pointer !important;
    }

    & .MuiSlider-markLabel {
      font-size: 1.1em;
      color: #edc137 !important;
      cursor: default !important;

      &.MuiSlider-markLabelActive {
        /* font-weight: bold;*/
      }

      .mobile & {
        font-size: 2rem;
      }
    }

    & .css-eg0mwd-MuiSlider-thumb{
      width: 40px !important;
      height: 40px !important;
      border-radius: 50% !important;
    }

    & .slider {
      width: 75%;
      margin: 0 auto;
      @include align-items();
      position: relative;

      &_options {
        &_item {
          @include column-align-justify();
          position: absolute;
          top: -40px;
          cursor: pointer;

          &.active_slider {
            & label {
              color: gold;
            }
          }

          & label {
            color: #eee;
            font-size: 1.15rem;
            text-align: center;
            padding-bottom: 9px;
            cursor: pointer;
            white-space: nowrap;
          }

          & div {
            background-color: $secondary-color;
            width: 2px;
            height: 12px;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      & input {
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  &_items {
    width: 100%;
    @include flex-wrap();
    /*   background: #fff;*/
    padding: 2rem 2rem 0;
    margin-bottom: 1rem;
    justify-content: center;
  }

  &_item {
    width: calc((100% - 2 * 2%) / 3);
    @include margin-end(2%);
    margin-bottom: 2%;
    /*box-shadow: 5px 5px 5px rgb(0 0 0 / 50%);*/
    border: 1px solid #787878;
    height: 480px;

    &_info > div {
      background-repeat: no-repeat;
      background-position: left center;
      html[lang=he] & {
        background-position: right center;
      }
    }
    &_countries {
      background-image: url("/www/images/tour_country.png");
    }

    &_days {
      background-image: url("/www/images/tour_days.png");
    }

    &_price {
      background-image: url("/www/images/tour_price.png");
    }

    h3 {
      font-size: 1em;
      font-weight: 400;
      .mobile & {
        font-size: 1.8em;
      }
    }

    &_button {
      position: absolute;
      bottom: 10px;
      right: 5px;
      html[lang=he] & {
        right: auto;
        left: 5px;
      }
    }

    &:nth-child(3n) {
      @include margin-end(0);
    }

    .mobile & {
      width: 100% ;
      @include margin-end(0);
    }

    &_picture {
      width: 100%;
      display: block;
      height: 100%;
      &_gradient {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%,rgba(0, 0, 0, 0.8) 100%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: column;
        & > {
          width: 100%;
          position: relative;
          height: 100%;
        }
      }


      &:hover {
        & .tours_item_title {
          /*          background: rgba(208, 119, 4, 0.75);*/
        /*  background: rgba(229, 199, 22, 0.8);*/
          min-width: 100%;
          bottom: 0;
          filter: brightness(1.1);
        }

        & .tour-pict {
          filter: contrast(1.15);
        }
      }
    }


    &_footer {
      @include flex-column;
      width: 100%;
      padding: 1%;
      background: #222;
      color: #f5f5f5;

      .mobile & {
        font-size: 1.75rem;
      }
    }

    .tour-pict {

      width: 100%;
      height: 100%;
      cursor: pointer;
      position: relative;
      display: block;
      text-decoration: auto;


      &.empty-pict {
        background-color: #8f8f8f;
      }
    }

    &_title {
      width: 100%;
      padding: 1rem;
      color: #000;

      & h2 {
        font-weight: 600;
        font-size: 24px;
        text-transform: uppercase;
        color: white;


        .mobile & {
         font-size: 2.5rem;
        }
      }

      .mobile & {
        bottom: 0rem;
      }
    }
    &_body {
      height: 117px;
      width: 100%;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px;

    }
    &_info {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      & > div {
        min-height: 20px;
        padding-inline-start: 30px;
      }
    }

    & label {
      font-weight: 600;
      @include margin-end(5px);
    }

    & .btn_more {
      width: auto;
      margin-inline-end: 10px;
      .mobile & {
        font-size: 1.2em;
      }
    }

    &_more {
      color: white;
      cursor: pointer;
      padding: 1rem 2rem;
      font-weight: 500;
      letter-spacing: 0.075rem;
      clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
      border: 1px solid silver;
      display: block;
      text-align: center;
      text-transform: uppercase;
    }

    &_day {

    }

    &_location {
      @include align-items();
      @include text-overflow;
      min-height: 20px;
      width: 100%;

      &_name {
        @include margin-end(3px);
      }

      &_separator {
        @include margin-end(3px);

        &:last-child {
          display: none;
        }
      }
    }
  }

}

.tour_info {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: url("/www/images/bikes/back-moto.jpg") no-repeat bottom;

  /*&::before {
    content: "";
    background-image: url(/www/images/bike_bg.jpg);
    background-position: top;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }*/

  &_wrap {
    @include flex-column();
   /* max-width: 1000px;
    margin: 0 auto;*/
    margin-bottom: 1rem;
    position: relative;
    background: white;
  }

  &_header {
    display: block;
    width: 100%;
    max-height: 450px;
    height: 450px;
    position: relative;
    overflow: hidden;

    &_gradient {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
      flex-direction: column;
    }
    & .tour_info_main {
      display: flex;
      height: auto;
      z-index: 20;
      top: 390px;
      position: absolute;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
      color: black;
      & .stars-item.active_star {
        color: white;
      }
    }
  }
  &_center {
    /*border: 1px solid red;*/
    display: flex;
    &_left {
      /*border: 1px solid green;*/
      flex-grow: 1;
      margin: 20px;
      h2 {
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &_right {
      width: 280px;
      /*border: 1px solid blue;*/
      color: black;
      margin: 20px;
      & .tour_info_main {
        height: 420px;
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 280px;
        border: 1px solid #D9D9D9;
        margin-top: 50px;
        &_items {
          flex-direction: column;
          justify-content: space-evenly;
          align-items: start;
        }
        &_button {
          width: 280px;
        }
      }
      & .tour_info_main_item_data {
        flex-direction: column-reverse;
      }
    }
  }

  &_caption {
    position: absolute;
    bottom: 50px;
    z-index: 10;
    color: #fff;
    text-align: left;
    transform: translate(0, -50%);
    left: 5%;
    html[lang=he] & {
      left: auto;
      right: 5%;
    }
    -webkit-padding-start: 15px;
    width: 100%;

    & h1.title {
      color: #fff;
      font-size: calc((100vw * 0.0331) - 10.75px);
      font-weight: 500;
      line-height: calc((100vw * 0.0245) - 10.25px);
      margin: 0;
    }
  }

  &_dates {
    position: absolute;
    right: 0;
    width: 250px;
    height: calc(100% - 60px);
    top: 0;

    html[lang=he] & {
      left: 0;
      right: unset;
    }

    &_wrap {
      @include flex-column();
      min-width: 50%;
      display: flex;
      justify-content: center;
      height: 100%;
    }
  }

  &_date {
    padding: 1.3rem 1.8rem 0;
    @include column-align-items();
    height: 6rem;
    color: #fff !important;
    position: relative;
    text-transform: uppercase;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.3s;
    text-decoration: none;
    min-width: 80px;
    margin-bottom: 2px;
    @include margin-start(0.25em);
    background-color: rgba(40, 106, 166, 0.8);

    &.active_date {
      background-color: #c20920;
      color: #fff;
    }

    & span {
      font-weight: 500;
    }

    & .sold-out{
      padding: 0.25rem 0.5rem;
      color: #000;
      font-weight: 700;
    }
  }

  &_main {
    &_items {
      flex-grow: 1;
      @include align-justify(center, start);
      @include flex-wrap();
      .tour_info_header & {
         flex-wrap: nowrap;
         justify-content: space-around;
      }

    }
    &_button {
      width: 350px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 60px;
    }
    &_item {
      /*@include padding-start(1.6rem);
      @include padding-end(1.6rem);*/
      text-align: center;
      position: relative;
      min-width: calc(100% / 6);

      &:last-child {
        &:after {
          border: unset;
        }
      }

      /*&:after {
        content: ' ';
        position: absolute;
        right: 0;
        top: 1rem;
        bottom: 1rem;
        @include border-end(#d8d8d8);
      }*/

      .mobile & {
        min-width: unset;
        border-bottom: 1px solid #eee;
      }

      &_data {
        @include flex-column();
        align-items: start;
        white-space: nowrap;
        padding: 0.6rem 1rem 0;
        text-align: left;
        font-weight: 700;
        .tour_info_header & {
          color: white;
          height: 4rem;
        }

        & small {
          display: block;
          /*color: #286aa6;*/
          font-size: 0.8rem;
          text-transform: uppercase;
          font-weight: 500;
          position: relative;
          bottom: -3px;
          html[lang=he] & {
            font-size: 1rem;
          }
        }

        &_name {
          white-space: normal !important;
          @include align-items();
          flex-grow: 1;
          flex-wrap: wrap;

          .tour_info_header & {
            flex-direction: row;
            flex-wrap: nowrap;
          }

          .tour_info_center & {
            align-items: start;
            flex-direction: column;
          }

          & .stars-item.active_star {
            color: black;
          }

          &.countries_name {
            & > * {
              @include margin-end(8px);
            }
          }

          & a {
            text-decoration: underline;
            color: inherit;
          }
        }
      }
    }
  }

  &_desc {
    @include d-flex;
    flex-direction: column;
    width: 100%;
    /*background: rgba(250, 250, 250, 0.7);
    box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
    min-height: 200px;
    max-width: 1400px;*/
    padding: 1rem;

    .mobile & {
      max-width: 100%;
      @include flex-column;
    }

    &_area {
      /*width: 40%;
      padding: 0.5rem;
      display: flex;
      background: #fff;
      margin: 1rem;
      flex-grow: 1;
      box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);*/

      .mobile & {
        width: calc(100% - 2rem);
        max-width: calc(100% - 2rem);
      }
    }

    &_text {
      flex-grow: 1;
      margin-top: 1rem;
      font-size: 18px;
      text-indent: unset;
      line-height: 150%;

      .mobile & {
        font-size: 0.9rem;
        line-height: 1rem;
      }
    }

    &_image {
      padding: 1rem;

      & img {
        min-width: 500px;
      }
    }
  }

  &_picture {
    @include flex-column;
    width: 60%;
    max-width: 850px;
    max-height: 500px;
    margin: 1rem;
    display: none;

    .mobile & {
      width: calc(100% - 2rem);
      max-width: calc(100% - 2rem);
    }

    &_area {
      max-height: 500px;
      width: 100%;
      padding: 0.5rem;
      display: flex;
      background: #fff;
      box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
    }

    &_main {
      flex-grow: 1;
      width: 80%;

      & .swiper {
        height: 100%;
        object-fit: contain;
        width: 100%;
        cursor: pointer;
        max-height: 500px;
      }

      &:hover {
        & img {
          transform: translateZ(0) scale(1);
          filter: contrast(1.3);
        }
      }

      & img {
        height: 100%;
        object-fit: contain;
        width: 100%;
        cursor: pointer;
        filter: contrast(1);
        transition: filter 0.6s steps(12, end), transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transform: translateZ(0) scale(0.92);
        transform-origin: 50% 50%;
      }
    }

    &_thumbnail {
      @include column-justify();
      width: 160px;
      @include margin-start(0.5rem);
      /*        height: calc(500px - 96px);*/
      height: 100%;
      /* background: #eee;*/
      background: #000;
      padding: 10px;

      .slider__prev,
      .slider__next {
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        height: 25px;
        min-height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        /*  color: #000;*/
        color: $secondary-color;
        font-weight: bold;
      }

      .slider__prev:focus,
      .slider__next:focus {
        outline: none;
      }

      & .slider__thumbs {
        height: calc(500px - 96px - 50px - 1rem);
      }

      & .swiper-slide {
        background-color: transparent;
      }

      & .slider__image {
        transition: 0.25s;
        width: 150px;
        overflow: hidden;

        &:hover {
          & img {
            @include transform(translateZ(0) scale(1.05));
            @include filter-contrast(1.3);
          }
        }

        & img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          cursor: pointer;
          @include filter-contrast();
          @include transition(filter .6s steps(12, end), transform .4s cubic-bezier(.785, .135, .15, .860));
          @include transform(translateZ(0) scale(1));
          @include transform-origin(50% 50%);
        }
      }

      & .swiper-slide:nth-child(2n) {
        width: 100% !important;
      }

      & .swiper-slide:nth-child(3n) {
        width: 100% !important;
      }
    }
  }

  &_overview {
    display: block;
    width: 100%;
/*    padding: 2rem;*/
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0 20px;

    &_container {
      @include flex-column;
      max-width: 1400px;
      margin: 0 auto;
    }

    &_header {
      & h2 {
        @include column-align-items();
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -1.5px;
        margin-bottom: 15px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &_columns {
      @include d-flex;
      width: 100%;
      padding: 0.5rem;
      flex-direction: column;
    }

    &_column {
      margin-top: 1rem;

      h5 {
        font-size: 1.1rem;
        text-transform: uppercase;
        margin-bottom: 0.75rem;
        padding: 1rem;
      }

      &_data {
        @include align-items(start);
        @include flex-wrap();
        padding: 1rem;
        flex-direction: column;

        .dates_column & {
          @include column-align-items(flex-start);
        }

        &_date{
          @include column-align-items(flex-start);
          width: 100%;
          max-width: 100%;
          text-decoration: unset;
          padding: 0.25rem 0.75rem;
          background: rgba(250, 250, 250, 0.7);
          box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
          border: 1px solid #747474;

          &_count{
            @include flex-column;
            width: 100%;
            padding: 0.25rem 0 0.5rem;
            border-bottom: 1px solid #989898;

            &_item{
              @include align-items();
              font-size: 0.92rem;

              &_label{
                font-style: italic;
                flex-grow: 1;
                @include text-overflow;
              }

              &_value{
                font-weight: 500;
              }
            }
          }

          &_reserve{
            @include flex-column;
            font-style: italic;
            padding: 0.5rem 0;
            width: 100%;

            &_left{
              @include flex-column;
              width: 100%;
              margin-bottom: 0.5rem;
            }

            &_reserve{
              @include flex-column;
              width: 100%;
              margin-bottom: 0.5rem;
            }

            &_header{
              font-weight: 700;
            }

            &_item{
              width: 100%;
              max-width: 100%;
              text-decoration: unset;
              @include align-justify(center, space-between);
              margin-bottom: 0.25rem;
              font-size: 0.92rem;

              &_label{
                font-style: italic;
                flex-grow: 1;
                @include text-overflow;
              }

              &_value{
                font-weight: 700;
              }
            }
          }

          &_waiting{
            padding: 0.5rem;
            @include align-justify();
            width: 100%;

            & .btn{
              line-height: 1;
              padding: 0.4rem .75rem;
              font-size: 0.85rem;
            }
          }
        }

        &_item {
          @include align-items();
          position: relative;
          margin-bottom: 0.25rem;
          /*text-indent: 5px;*/
          width: 100%;
        }

        &_value {
          @include text-overflow;
          /*@include margin-end(2%);*/
          color: inherit;
          font-style: italic;

          .tour_info_main_item_data & {
            overflow: unset;
            text-overflow: unset;
            white-space: nowrap;
          }

          .con-tooltip & {
            text-decoration: underline;
          }

          .dates_column & {
            width: 100%;
            max-width: 100%;
            background-color: #fff;
            text-decoration: unset;
            @include align-justify(center, space-between);
            padding: 0.25rem 0.75rem;
            box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
            border: 1px solid #747474;

            & span {
              font-weight: 500;
              @include margin-end(0.5em);
            }

            & .btn {
              line-height: 1;
              padding: 0.4rem .75rem;
              font-size: 0.85rem;
            }
          }
        }

        &_tooltip {
          display:  block;
          box-shadow: 2px 4px 8px rgb(0 0 0 / 30%);

          &_picture {
            float: left;
            margin-top: 0;
            margin-bottom: 7px;
            width: 250px;
            height: 200px;
            @include margin-start(0);
            @include margin-end(1rem);

            &:hover {
              & img {
                transform: translateZ(0) scale(1);
                filter: contrast(1.3);
              }
            }

            & img {
              height: 100%;
              object-fit: contain;
              width: 300px;
              cursor: pointer;
              filter: contrast(1);
              transition: filter 0.6s steps(12, end), transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
              transform: translateZ(0) scale(0.96);
              transform-origin: 50% 50%;
            }
          }

          &_desc{

          }
        }
      }
    }

    &_item {
      /*min-height: 167px;*/
      border: 1px solid #D9D9D9;
      margin-bottom: 20px;
      &_desc {
        padding: 20px 40px;
        position: relative;
        font-size: 14px !important;
        line-height: 25px;
        background: none !important;
        width: 638px;
        .dates_column & {
          width: auto;
        }
        .mobile & {
          width: auto;
        }
        &_area {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          position: relative;
          .mobile & {
            flex-direction: column;
          }
          /*min-height: 165px;*/
        }
      }
      &_gallery {
        width: 600px;
        display: flex;
        overflow: hidden;
        .dates_column & {
          width: auto;
        }
        .mobile & {
          margin: 0 40px;
          width: calc(100% - 80px);
        }
        .image {
          flex-grow: 1;
          background-size: cover;
          cursor: pointer;
          .mobile & {
            min-height: 200px;
          }
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      h3 {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
      }
      &_info {
        white-space: pre-wrap;
        font-size: 14px;
      }
      &_highlights {
        display: flex;
        label {
          font-weight: bold;
          width: 150px;
        }
        .value {

        }
      }
      &_button .btn {
        margin-inline-end: 10px;
      }
    }

    &_list {
      padding: 20px 40px;
      display: flex;
      flex-wrap: wrap;
      &_item {
        font-style: italic;
        margin: 0.5rem;
      }
    }
  }

  &_days {
    display: block;
    width: 100%;
    background-color: #fff;
    margin-bottom: 1rem;
    padding: 0 20px;

    &_wrap {
      margin: 0 auto;
    }

    &_items {
      @include flex-column;
      position: relative;
      width: 100%;
    }

    &_item {
      width: auto;
      min-height: 167px;
      &.show_more {
        min-height: 167px;
        height: auto;
      }
      border: 1px solid #D9D9D9;
      margin-bottom: 20px;

      &_desc {
        flex-grow: 1;
      }
      &_gallery {
        width: 600px;
        display: flex;
        overflow: hidden;
        > div {
          flex-grow: 1;
          background-size: cover;
          margin-inline-start: -47px;
          clip-path: polygon(100% 0%, 15% 0%, 0% 100%, 85% 100%);
          cursor: pointer;
          &:first-child {
            clip-path: polygon(100% 0, 0 0, 0% 100%, 85% 100%);
            html[lang=he] & {
              clip-path: polygon(100% 0, 15% 0, 0% 100%, 100% 100%);
            }
            margin-inline-start: 0;
          }
          &:last-child {
            clip-path: polygon(100% 0, 15% 0, 0% 100%, 100% 100%);
            html[lang=he] & {
              clip-path: polygon(100% 0, 0 0, 0% 100%, 85% 100%);
            }
          }
          &:first-child:last-child {
            clip-path: none;
            margin: 0;
            html[lang=he] & {
              clip-path: none;
            }
          }
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      &_mobile_gallery {
        img {
          width: calc(100% - 80px);
          margin: 0 40px;
        }
      }
      &_image {
        background-size: cover;
        &_gradient {
          height: 100%;
          background: linear-gradient(90deg, #FAFAFA 0%, rgba(255, 255, 255, 0) 50%);
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          html[lang=he] & {
            background: linear-gradient(270deg,#fafafa,hsla(0,0%,100%,0) 50%);
          }
        }
      }
      &_btn {
        width: 34px;
        height: 34px;
        border: 1px solid #ccc;
        background: #ffffff;
        text-align: center;
        margin: 20px;
        cursor: pointer;
      }
      &_more {
        padding: 20px 40px;
        display: none;
        transition: all 0.4s ease-in-out;
        .show_more & {
          display: block;
        }
      }
      &_info {
        white-space: pre-wrap;
        font-size: 14px;
      }
      & h3 {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
      }

      &_desc {
        padding: 20px 40px;
        &_area {
          @include flex-row;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          position: relative;
          min-height: 165px;
        }

        position: relative;
        font-size: 14px !important;
        line-height: 25px;
        background: none !important;
        width: 638px;

        & label {
          font-weight: bold;
        }
      }

      &_number {
        color: #888787;
        font-size: 18px;
        font-weight: 600;
      }

      &_image {
        width: 500px;
      }

      &_highlights {
        @include align-items();
        @include flex-wrap();
        width: 100%;

        &_value {
          margin: 0 5px;
        }
      }

      &_images {
        padding: 0.5rem 1rem;
        @include flex-column;
        width: 400px;
        height: calc(94px + 1rem);
        background: rgba(250, 250, 250, 0.7);
        .mobile & {
          width: auto;
          height: auto;
        }
      }
    }
  }

  &_gallery {
    @include d-flex;
    background-color: #fff;
    height: 290px;
    width: 100%;
    @include padding-start(1rem);
    @include padding-end(1rem);

    &_slider {
      height: 270px;
    }
  }

  &_rules{
    padding: 1rem;
    display: block;
    width: 100%;

    &_wrap {
      @include flex-column;
      max-width: 1400px;
      margin: 0 auto;
    }

    &_area{
      @include flex-column;
      width: 100%;
    }

    &_column{
      @include flex-column;
      padding-bottom: 10px;
    }

    &_name{
      font-size: 16px;
      line-height: 30px;
      font-weight: 700;
      text-transform: uppercase;

      .mobile & {
        font-size: 1.75rem;
      }
    }

    &_items{
      @include flex-column;
      color: #393e41;
      padding: 10px 20px;

      .mobile & {
        font-size: 1.75rem;
      }

      &_row{
        margin-bottom: 0.75rem;
        list-style: disc;
        font-size: 16px;
        margin-inline-start: 20px;
      }
    }
  }
}

.tour_book {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: url("/www/images/bikes/back-moto.jpg") no-repeat bottom;

  > .w-wrap {
    background: white;
  }

  & .tour_info_rules {
    margin: 0 20px;
  }

 /* &::before {
    content: "";
    background-image: url("/www/images/bike_bg.jpg");
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
  }*/

  &_checkout{
    margin: 1rem auto;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 500px;

    &_layer{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255,255,255,0.95);
      z-index: 100;
      @include align-justify();
      transition: 3.5s ease-out;

      &.disable_layer{
        left: -1900px;
        right: unset;

      }
    }

    &_success{
      @include column-align-justify();
      background-color: rgba(255,255,255,0.95);
      width: 100%;

      &_link{
        & a{
          @include margin-start(15px);
          text-decoration: underline;
        }
      }
    }
  }
}

.tour_waiting{
  &_data{
    @include flex-column;
    padding: 25px 1rem;
  }

  &_header{
    @include column-align-items();
    width: 100%;
  }

  &_name{
    font-weight: 700;
    font-size: 1.25rem;
  }
  &_dates{
    font-weight: 500;
    font-size: 1.1rem;
    font-style: italic;
    margin-bottom: 0.5rem;
  }
  &_text{
    font-size: 0.95rem;
  }

  &_form{
    @include column-align-items();
    width: 100%;
    padding: 1rem;
  }

  &_footer{
    padding: 0.5rem 1rem;
    @include align-justify(center,space-evenly);
  }
}

.con-tooltip {
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: default;
  z-index: 10;
  margin: 3px;
  vertical-align: middle;
}

/*tooltip */
.tooltip {
  visibility: hidden;
  z-index: 1;
  opacity: .40;
  width: 20vw;
  font-size: 0.9em;

  padding: 5px 20px;

  background: #eee;
  color: #000;

  position: absolute;
  bottom: 3rem;

  left: -25%;
  html[lang=he] & {
    left: auto;
    right: -25%;
  }

  border-radius: 9px;

  transform: translateY(9px);
  transition: all 0.3s ease-in-out;

  box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
  .mobile & {
    left: unset;
    width: auto;
  }
}


/* tooltip  after*/
.tooltip::after {
  content: " ";
  width: 0;
  height: 0;

  border-style: solid;
  border-width: 12px 12px 0 12px;
  border-color: #65666c transparent transparent transparent;

  position: absolute;
  left: 3px;
  bottom: -12px;
  html[lang=he] & {
    left: auto;
    right: 3px;
  }

}

.con-tooltip:hover .tooltip {
  visibility: visible;
 /* transform: translateY(-10px);*/
  opacity: 1;
  transition: .3s linear;
  /*animation: odsoky 1s ease-in-out infinite alternate;*/

}


/*hover ToolTip*/
.left:hover {
  /*transform: translateX(-6px);*/
}

.top:hover {
  /*transform: translateY(-6px);*/
}

.bottom:hover {
  transform: translateY(6px);
}

.right:hover {
  transform: translateX(6px);
}

/*left*/

.left .tooltip {
  top: -20%;
  left: -170%;
}

.left .tooltip::after {
  top: 40%;
  left: 90%;
  transform: rotate(-90deg);
}

/*bottom*/

.bottom .tooltip {
  top: 115%;
  left: -20%;
}

.bottom .tooltip::after {
  top: -17%;
  left: 40%;
  transform: rotate(180deg);
}

/*right*/

.right .tooltip {
  top: -20%;
  left: 115%;
}

.right .tooltip::after {
  top: 40%;
  left: -12%;
  transform: rotate(90deg);
}

@keyframes odsoky {
  0% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(1px);
  }

}

@import '../../styles/themes/default';
@import '../../styles/mixin';
@import '../../styles/patterns';

.modal{
  &-container {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99900;
  }

  &-layer{
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgb(21,21,21,.55);
    width: 100%;
    z-index: 99901;
  }

  &-wrap{
    z-index: 99902;
    @include flex-column();
    width: 600px;
    background: #fff;
    position: absolute;
    top: 100px;
    left: calc(50% - 220px);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;

    .large-modal & {
      width: 1200px;
      left: calc(50% - 570px);
    }

    .medium-modal & {
      width: 800px;
      left: calc(50% - 380px);
    }

    .terms-modal & {
      top:5vh;

      & .privacy_text{
        max-height: 85vh;
        overflow-y: auto;
        @extend %scrollbar;
      }
    }

    .package-order-modal & {
      width: 1000px;
      left: calc(50% - 490px);
      top:5dvh;
      min-height: 70dvh;

      .mobile & {
        bottom:5dvh;
        min-height: 90dvh;
        height: 90dvh;
        overflow-y: auto;
      }
    }

    .gallery-viewer-modal & {
      width: 70vw;
      left: 15vw;
      top: 3vh;
      /*height: 94vh;*/
      max-height: 94vh;
      /*padding: 30px 0 20px;*/
      text-align: center;
      background: #ffffff;
      justify-content: center;

      & .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: 82vh !important;
      }

      .image-gallery-content.fullscreen {
        height: 100vh !important;
        padding-top: 5vh !important;
      }
    }

    .mobile & {
      right: 5vw;
      width: 90vw;
      left: 5vw;
    }
  }

  &-header{
    @include align-justify();
    position: relative;
    height: 40px;
    min-height: 40px;
    background: #ccc;
    font-size: 18px;
    border-bottom: 1px solid #aaa;
    z-index: 99903;

    .mobile & {
      height: 4rem;
      font-size: 2.25rem;
    }

    &-label{

    }
    &-close{
      position: absolute;
      font-size: 18px;
      font-weight: bold;
      top: 8px;
      right: 8px;
      cursor:pointer;

      & svg{
        color: #c50000;

        &:hover{
          transform: scale(1.2);
        }
      }
    }
  }

  &-content{
    min-height: 300px;
    @include flex-column;
    padding: 25px 15px;
  }

  &-footer{
    @include align-items();
    height: 30px;
    background: #ccc;
    padding: 10px;
    border-top: 1px solid #aaa;
    width: calc(100% - 20px);

    &-buttons{
      @include align-justify(center,space-evenly);
      width: 100%;
    }

    .modal-content & {
      width: 100%;
      margin: 0 -10px -25px;
    }
  }

  &-message{
    @include align-justify();
    width: calc(100% - 40px);
    min-height: 25px;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    transition: 0.4s;
    font-size: 14px;

    &.show-message{
      top: 40px;
      opacity: 1;
      width: calc(100% );
    }

    &.good{
      background: #2aa403;
      color: #fff;
    }
    &.bad{
      background: #a40303;
      color: #fff;
    }
  }

  &-tabs{
    @include d-flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 25px;

    &-item{
      @include align-justify();

      &-link{
        display: block;
        padding: 0.5rem 1rem;
        color: #0e6ea2;
        text-decoration: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
        margin-bottom: -1px;
        background: 0 0;
        border: 1px solid transparent;
        border-top-left-radius: 0.2308rem;
        border-top-right-radius: 0.2308rem;
        isolation: isolate;
        cursor: pointer;

        &.active_tab{
          color: #333;
          background-color: #fff;
          border-color: #dee2e6 #dee2e6 #fff;
          cursor: default;
        }
      }
    }
  }
}
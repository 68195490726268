@import "../styles/mixin";


.privacy {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background-image: url("/www/images/bike_bg.jpg");
  background-size: cover;
  background-position: center;

  &_wrap{
    min-height: inherit;
    background: white;
    padding: 1.5rem 3.5rem;
    font-size: 16px;
  }

  &_text{
    background: #fff;
    padding: 1rem 1.5rem;
    width: 100%;
    /*box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);*/
    text-indent: 20px;
    white-space: pre-line;

    & ul {
      text-indent: 0;
    }
  }
}
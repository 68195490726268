@import './variables';
@import './styles/mixin';
@import './styles/patterns';
*{
  @extend %scrollbar;
}

#root {
  display: flex;
  background-color: #ffffff;
  min-width: 950px;

  .mobile & {
    min-width: auto;
  }
}

.app {
  width: 100%;
  position: relative;
  /*   z-index: 0;
     max-width: 100%!important;
     margin: 0!important;*/
}

.w-full {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  min-width: 1180px;

  .mobile & {
    max-width: unset;
    min-width: unset;
  }
}

.w-wrap {
  width: 1180px;
  margin: 0 auto;
  max-width: 1180px;
  height: 100%;

  .mobile & {
    width: 100%;
    max-width: 100%;
  }
}

.w-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 3;
}

header {
  height: $header_height;
  background: #F5F5F5;
  margin: $header_margin 0;
  top: 0;
  position: sticky;
  z-index: 1020;

  .header {
    &_wrap {
      flex-wrap: nowrap;
      @include align-justify(center, space-between);
      width: 1240px;
      max-width: 1240px;
    }

    &_roulette {
      order: 4;
      display: block;
      height: 50px;
      width: 50px;
      @include margin-start(13px);
      @include margin-end(5px);

      & img {
        cursor: pointer;
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  .logo {
    &__heading {
      flex: .0714285714 0 auto;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      order: 1;
    }

    &__heading-link {
      display: flex;
      width: 120px;
      height: 107px;
      padding: 0;
      background: url("/www/images/moto-logo.svg") no-repeat;
      background-size: cover;
      cursor: pointer;
      html[lang=he] & {
        background-image: url("/www/images/tamamoto-logo.png");
      }
      body.cnt-IL & {
        background-image: url("/www/images/tamamoto-logo.png");
      }
    }
  }

  .menu_icon {
    width: 50px;
    height: 50px;
    display: none;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  .menu {
    height: 100%;
    display: flex;
    -webkit-box-flex: .8571428571;
    /*flex-grow: .8571428571;*/
    width: 700px;
    flex-shrink: 0;
    flex-grow: 1;
    order: 2;

    ul {
      flex: 1 0 auto;
      display: flex;
      list-style: none;
      margin: 0;
      padding-left: 0;
    }

    .menu-item {
      font-size: 1.5rem;
      flex: 1 1 auto;
      justify-content: center;
      display: flex;
      align-items: center;

      a {
        /*font-size: 1.2857rem;*/
        font-size: 1.2rem;
        color: #000000;
        text-transform: uppercase;
        text-decoration: none;
        position: relative;
        height: 30px;
        line-height: 2rem;

        & .new-item{
          position: absolute;
          top: -41px;
          height: 64px;
          right: 31%;
        }

        &:hover, &.active {
          color: #d90303;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.1em;
          background-color: #d90303;
          opacity: 1;
          transition: opacity 300ms, transform 300ms;
          transform: scale(0);
          transform-origin: center;
        }

        &:hover::after,
        &:focus::after,
        &.active::after {
          opacity: 1;
          transform: scale(1);
        }

        span {
          display: inline-block;
          text-align: center;
        }
      }
    }
  }
}

.mobile {
  header {
    .header_wrap {
      @include justify(space-between);
      @include padding-start(20px);
      @include padding-end(20px);

      .menu_icon {
        display: block;
        width: 70px;
        height: 70px;

        &:hover {
          border: 1px solid #d83737;
        }
      }

      .menu {
        position: absolute;
        top: 107px;
        width: 100%;
        height: calc(100vh - 108px);
        transition: all 0.4s ease-in-out;
        background: rgba(255, 255, 255, 0.8);
        padding-bottom: 100vh;
        left: -100%;
        right: 0;

        html[lang=he] & {
          left: 0;
          right: -100%;
        }

        &.expand {
          display: flex;
          height: calc(100vh - 108px);
          left: 0;

          html[lang=he] & {
            right: 0;
          }
        }

        ul {
          flex-direction: column;
          height: calc(100vh - 108px);
          background: white;
          width: 85%;

          li {
            border-bottom: 1px solid #DFE1E8;
            flex: 0 0 7rem;
            line-height:7rem;
            height: 7rem;
          }
        }
      }

      .menu-item a {
        font-size: 2.5rem;
        height: 100%;
        width: 100%;
        text-align: center;
        line-height: unset;

        & .new-item{
          top: -3.5rem;
          height: 111px;
        }

      }
    }
  }
}


.header {
  height: $header_height;


}

.profile {
  position: relative;
  order: 5;
  cursor: pointer;

  &_row{
    @include align-items();
    padding: 20px;
    font-size: 26px;

    .mobile & {
      @include align-justify();
    }
  }

  &_icon{
    @include margin-end(10px);

    .logined & {
      color: #d90303;
    }

    .mobile & {
      font-size: 2.7rem;
      @include margin-end(0px);
    }
  }

  &_down{
    font-size: 20px;

    .mobile & {
      display: none;
    }
  }

  &_dropdown{
    width: 225px;
    position: absolute;
    z-index: 20;
    transition: all 0.3s ease-in-out 0s;
    transform-origin: center top;
    transform: scaleY(0);
    margin-top: 12px;
    top: 40px;
    @include end(-20px);
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .mobile & {
      width: 92vw;
    }

    .hovered & {
      transform: scaleY(1);
    }

    & ul {
      & li {
        margin-bottom: 8px;

        &:hover{
          background-color: #00a04633;
        }

        & > div {
          text-decoration: none;
          @include align-items();
          padding: 10px 24px;
          font-size: 14px;
          color: rgb(0, 47, 52);

          .mobile & {
            font-size: 2.25rem;
            padding: 12px 24px;
          }
        }
      }
    }

    &_user{
      @include align-justify();
      padding: 16px 24px 8px;
      border-bottom: 1px solid rgb(216, 223, 224);
      cursor: default;
    }

    &_icon{
      & svg {
        height: 32px;
        width: 32px;
        @include margin-end(14px);
/*        color: rgb(0, 47, 52);*/
        color: #d90303;

        .mobile & {
          height:3.25rem;
          width: 3.25rem;
        }
      }
    }
    &_name{
      font-size: 16px;
      font-weight: 600;
      flex: 1 1 0%;
     @include text-overflow;

      .mobile & {
        font-size: 2.5rem;
      }
    }
    &_header{
      line-height: 22px;
      padding: 8px 24px;
      font-size: 16px;
      font-weight: 600;
      color: rgb(127, 151, 153);
      border-bottom: 1px solid rgb(216, 223, 224);
      background: #fafafa;
      cursor: default;

      .mobile & {
        font-size: 2.15rem;
        padding: 16px 24px;
      }
    }
  }
  &_logout{
    border-top: 1px solid rgb(216, 223, 224);
    text-decoration: none;
    @include align-items();
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(0, 47, 52);

    .mobile & {
      font-size: 2.5rem;
      padding: 24px 24px;
    }
  }
}

.page {
  min-height: calc(100vh - $page_diff);
  position: relative;
  /*   z-index: 0;
     margin-bottom: $footer_height;*/

  &-header {
    padding: 1.25rem 1rem;

    .mobile & {
      font-size: 1.56rem;
    }
  }

  .mobile & {
    min-height: calc(100vh - $page_mobile_diff + $header_height);
  }
}

.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

small {
  font-size: 80%;
  font-weight: normal;
  opacity: .6;
}

.stars {
  @include align-items();
  max-width: max-content;

  &-item {
    @include margin-end(2px);

    & svg {
      cursor: pointer;
    }

    &.active_star {
      /*  color: #2574b9;*/
      color: #b94025;
    }

    &.hover_star {
      color: gold;
    }
  }
}

h1 {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.text_desc {
  font-weight: 300;
  text-indent: 20px;
  white-space: pre-line;
}

.danger_text {
  color: red !important;
  font-weight: 700 !important;
}

.under_construction {
  width: 100%;
  min-height: calc(100vh - 257px);
  @include align-justify();
  background: #ffcf00;
}

.clickable {
  cursor: pointer !important;
}

.not-allowed {
  cursor: not-allowed !important;
}

.opacity {
  &-25 {
    opacity: 0.25;
  }

  &-50 {
    opacity: 0.5;
  }

  &-75 {
    opacity: 0.75;
  }
}

.input-phone input {
  direction: ltr;


  html[lang=he] & {
    text-align: right;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: none;
    transform: none;
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


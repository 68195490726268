@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin padding-start($val) {
  -webkit-padding-start: $val;
  -moz-padding-start: $val;
}

@mixin padding-end($val) {
  -webkit-padding-end: $val;
  -moz-padding-end: $val;
}

@mixin margin-start($val) {
  -webkit-margin-start: $val;
  -moz-margin-start: $val;
}

@mixin margin-end($val) {
  -webkit-margin-end: $val;
  -moz-margin-end: $val;
}

@mixin padding($pads...) {
  padding: $pads;
}

@mixin layer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-column {
  @include d-flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-row {
  @include d-flex;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin justify($val:center) {
  @include d-flex;
  -webkit-box-pack: $val;
  -ms-flex-pack: $val;
  justify-content: $val;
}

@mixin column-justify($val:center) {
  @include d-flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: $val;
  -ms-flex-pack: $val;
  justify-content: $val;
}

@mixin column-align-justify($val:center,$val2:center) {
  @include d-flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: $val2;
  -ms-flex-pack: $val2;
  justify-content: $val2;
  -webkit-box-align: $val;
  -ms-flex-align: $val;
  align-items: $val;
}

@mixin column-align-items($val:center) {
  @include flex-column;
  -webkit-box-align: $val;
  -webkit-align-items: $val;
  -ms-flex-align: $val;
  align-items: $val;
}

@mixin font-style-weight($style,$weight) {
  font-style: $style;
  font-weight: $weight;
}

@mixin align-items($val:center) {
  @include d-flex;
  -webkit-box-align: $val;
  -ms-flex-align: $val;
  align-items: $val;
}

@mixin align-justify($align:center,$justify:center) {
  @include d-flex;
  -webkit-box-align: $align;
  -ms-flex-align: $align;
  align-items: $align;
  -webkit-box-pack: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
}

@mixin align-grow($align:center,$grow:1) {
  @include d-flex;
  -webkit-box-align: $align;
  -ms-flex-align: $align;
  align-items: $align;
  -webkit-box-flex: $grow;
  -ms-flex-positive: $grow;
  flex-grow: $grow;
}

@mixin flex-grow($val:1) {
  -webkit-box-flex: $val;
  -ms-flex-positive: $val;
  flex-grow: $val;
}

@mixin column-grow($val: 1) {
  @include d-flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: $val;
  -ms-flex-positive: $val;
  flex-grow: $val;
}

@mixin align-self($val) {
  -ms-flex-item-align: $val;
  -ms-grid-row-align: $val;
  align-self: $val;
}

@mixin flex-shrink($val:1) {
  -ms-flex-negative: $val;
  flex-shrink: $val;
}

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-wrap($val:wrap) {
  @include d-flex;
  -ms-flex-wrap: $val;
  flex-wrap: $val;
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transform($val) {
  -ms-transform: $val;
  transform: $val;
}

@mixin transform-origin($val) {
  -webkit-transform-origin: $val;
  transform-origin:$val;
}

@mixin flex-basis($val:auto) {
  -ms-flex-preferred-size: $val;
  flex-basis: $val;
}

@mixin flex($args...) {
  flex: $args;
  -ms-flex: $args;
}

@mixin font-smoothing-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin user-select($val:auto) {
  user-select: $val;
  -webkit-user-select: $val;
  -moz-user-select: $val;
  -ms-user-select: $val;
}

@mixin filter-grayscale($val:100%) {
  -webkit-filter: grayscale($val);
  filter: grayscale($val);
}

@mixin filter-contrast($val:1) {
  -webkit-filter: contrast($val);
  filter: contrast($val);
}

@mixin background-size($val:cover) {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin two-colored-border($from, $dir, $first_color, $second_color, $separator) {
  -webkit-border-image: -webkit-linear-gradient($from, $first_color $separator, $second_color $separator);
  -moz-border-image: -moz-linear-gradient($from, $first_color $separator, $second_color $separator);
  -o-border-image: -o-linear-gradient($from, $first_color $separator, $second_color $separator);
  border-image: linear-gradient(to $dir, $first_color $separator, $second_color $separator);
  border-image-slice: 1;
}

@mixin box-sizing($val:border-box){
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin animation($val){
  -webkit-animation: $val;
  -moz-animation: $val;
  -ms-animation: $val;
  -o-animation: $val;
  animation: $val;
}

@mixin sticky {
  position: sticky;
  position: -webkit-sticky;
}

@mixin transition($val...){
  -webkit-transition: $val;
  -moz-transition: $val;
  -ms-transition: $val;
  -o-transition: $val;
  transition: $val;
}

@mixin border-start($color: $light_border_color, $width: 1px, $style: solid) {
  border-left: $width $style $color;

  .he &, [lang="he"] & {
    border-left: 0;
    border-right: $width $style $color;
  }
}

@mixin border-end($color: $light_border_color, $width: 1px, $style: solid) {
  border-right: $width $style $color;

  .he &, [lang="he"] & {
    border-right: 0;
    border-left: $width $style $color;
  }
}

@mixin start($val:0) {
  left: $val;

  .he &, [lang="he"] & {
    left: unset !important;
    right: $val;
  }
}

@mixin end($val:0) {
  right: $val;

  .he &, [lang="he"] & {
    right: unset !important;
    left: $val;
  }
}

@mixin round($d) {
  border-radius: 50%;
  width: $d;
  height: $d;
  @include align-justify();
}

@mixin number-format {
  .he & {
    direction: ltr;
  }
}

@mixin translateX($val:100%) {
  @include transform(translateX($val));

  .he &, [lang="he"] & {
    @include transform(translateX($val * -1));
  }
}
@import "../../../styles/mixin";
@import "../../../styles/themes/default";

.time-s-row{
  @include align-items();
  padding: 5px;
  font-size: 110%;
  .mobile & {
    font-size: 2rem;
  }


  .time-label{
    width: 40%;
    @include align-items();
    @include padding-end(15px);
  }
  .time-day{
    width: 25%;

    .mobile & {
      width: 35%;
    }
  }
  select {
    width: 25%;
    font-size: 85% !important;
  }
}

.bike-details_checkout_layer{
  .time_selector {
    width: 450px;
  }

  .booking-block{
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
}
.mobile .bike-details_checkout_layer {
  .time_selector {
    width: 600px;
  }
}
.time-s-row{
  .not-work{
    color: red;
  }
  option{
    color: black;
  }
}
.time-s-desc {
  margin-top: 5px;
  font-style: italic;
  font-size: 0.85em;
}
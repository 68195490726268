@import '../../styles/themes/default';
@import '../../styles/mixin';

.profile {
  &_content{
    @include justify();
    width: 100%;
  }

  &_side{
    @include flex-column;
    width: 300px;

    .mobile & {
     display: none;
    }

    & nav {
      display: block;
    }
  }
  &_user{
    margin-bottom: 4px;
    padding: 16px 8px 8px;
    border-bottom: 1px solid #e9e9e9;

    &_item{
      @include align-items();
      border-radius: 8px;
      padding: 8px;
      font-size: 14px;
      color: #221f1f;
      transition: all .2s ease-in-out;
    }

    &_icon{
      @include align-justify();
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      @include margin-end(16px);
      border-radius: 50%;
      background-color: #221f1f0d;
      transition: all .2s ease-in-out;
      position: relative;

      & svg {
        width: 24px;
        height: 24px;
      }
    }

    &_area{
      flex: 1;
      min-width: 0;
    }

    &_name{
      @include text-overflow;
    }
    &_mail{
      font-size: 12px;
      color: #797878;
      @include text-overflow;
    }
  }

  &_menu{
    margin-bottom: 8px;
    padding: 0 8px 8px;
    border-bottom: 1px solid #e9e9e9;

    &_item{
      color: #221f1f;
      @include align-items();
      border-radius: 8px;
      padding: 4px 8px;
      font-size: 14px;
      transition: all .2s ease-in-out;
      margin-bottom: 5px;
      text-decoration: none;
      cursor: pointer;

      &.active_item, &:hover{
        background-color: #00a04633;

        & .profile_menu_item_icon{
          background-color: initial;
        }
      }

      &_icon{
        @include align-justify();
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        @include margin-end(16px);
        border-radius: 50%;
        background-color: #221f1f0d;
        transition: all .2s ease-in-out;
      }
    }
  }
  &_main{
    @include flex-column;
    width: calc(100% - 300px);
    @include padding-start(40px);
    @include border-start(#e9e9e9);
    padding-top: 24px;
    min-height: calc(100vh - 397px);

    .mobile & {
      width: 100%;
      padding: 30px 17px;
    }
  }

  &_heading{
    font-size: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 5px;

    .mobile & {
      font-size: 32px;
    }
  }
}